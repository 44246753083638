import * as React from 'react';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import { InView } from 'react-intersection-observer';

import { LazyImage } from 'main/components/LazyImage';
import { SEO } from 'main/components';

import fpvctrlNoLeds from '../../../main/assets/images/fpvctrl/fpvctrl-no-leds.webp';
import iconLed from '../../../main/assets/icons/icon-led.svg';
import discorverFeel from '../../../main/assets/images/fpvctrl/discover-feel.webp';
import fpvctrlBack from '../../../main/assets/images/fpvctrl/fpvctrl-back.webp';
import discoverButtons from '../../../main/assets/images/fpvctrl/discover-buttons.webp';
import fpvctrlDiscoverSide from '../../../main/assets/images/fpvctrl/fpvctrl-discover-side.webp';
import discoverCharging from '../../../main/assets/images/fpvctrl/discover-charging.webp';
import fpvctrlFront from '../../../main/assets/images/fpvctrl/fpvctrl-front.webp';
import fpvctrlSide from '../../../main/assets/images/fpvctrl/fpvctrl-side.webp';
import logoOrqaSymbol from '../../../main/assets/images/orqa-logo.png';
import iconMap from '../../../main/assets/icons/icon-map.svg';
import iconPc from '../../../main/assets/icons/icon-pc.svg';
import iconDrone from '../../../main/assets/icons/icon-drone.svg';
import iconGear from '../../../main/assets/icons/icon-gear.svg';
import skydiveDrone from '../../../main/assets/images/fpvctrl/skydive-drone.webp';
import fpvctrlBottom from '../../../main/assets/images/fpvctrl/fpvctrl-bottom.webp';
import fpvctrlPhone from '../../../main/assets/images/fpvctrl/phone.webp';
import fpvctrlModels from '../../../main/assets/images/fpvctrl/fpvctrl-models.webp';
import fpvctrlMenu from '../../../main/assets/images/fpvctrl/fpvctrl-menu.webp';
import fpvctrlInfo from '../../../main/assets/images/fpvctrl/fpvctrl-info.webp';
import fpvconnectGoogleplay from '../../../main/assets/pages/fpvconnect/fpvconnect-googleplay.webp';
import fpvConnectAppstore from '../../../main/assets/pages/fpvconnect/fpvconnect-appstore.webp';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class FPVCtrl extends React.Component<{}, State> {
  state: State = {
    loading: true,
    isMobile: false,
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const settingsMobile = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };

    return (
      <>
        <SEO seoTitle="ORQA - FPV.Ctrl" />
        <section className="fpvctrl">
          <section className="fpvctrl__header--wrapper">
            <div className="fpvctrl__header--content">
              <h1 className="fpvctrl__header--title">FPV.Ctrl</h1>
              <p className="fpvctrl__header--description">
                Wireless radio controller for FPV drones and simulators.
              </p>
              <a
                href="https://shop.orqafpv.com/products/orqa-orqa-fpv-ctrl-radio-controller"
                className="btn btn--primary btn--med"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                Buy now
              </a>
            </div>
            <InView triggerOnce={false}>
              {({ ref, inView }) => {
                return (
                  <div ref={ref} className="fpvctrl__header--secondary">
                    <LazyImage
                      src={fpvctrlNoLeds}
                      alt="fpvctrl"
                      className="fpvctrl__header--img"
                    />
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView ? 1 : 0 }}
                      transition={{ duration: 0.4, delay: 1.5 }}
                      src={iconLed}
                      alt="fpvctrl"
                      className="fpvctrl__header--icon fpvctrl__header--icon-1"
                    />
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView ? 1 : 0 }}
                      transition={{ duration: 0.8, delay: 1.5 }}
                      src={iconLed}
                      alt="fpvctrl"
                      className="fpvctrl__header--icon fpvctrl__header--icon-2"
                    />
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView ? 1 : 0 }}
                      transition={{ duration: 1.2, delay: 1.5 }}
                      src={iconLed}
                      alt="fpvctrl"
                      className="fpvctrl__header--icon fpvctrl__header--icon-3"
                    />
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: inView ? 1 : 0 }}
                      transition={{ duration: 1.6, delay: 1.5 }}
                      src={iconLed}
                      alt="fpvctrl"
                      className="fpvctrl__header--icon fpvctrl__header--icon-4"
                    />
                  </div>
                );
              }}
            </InView>
          </section>

          <section
            id="DiscoverCtrl"
            className="wrapper wrapper--wide fpvctrl__wrapper t-center"
          >
            <h2 className="fpvctrl__discover--title">Discover FPV.Ctrl.</h2>
            <div className="fpvctrl__slider--wrapper">
              <Slider {...settings} className="s-bottom--xlrg">
                <div className="fpvctrl__discover--slider">
                  <LazyImage
                    src={discorverFeel}
                    alt="Fpvctrl feel & fit"
                    className="fpvctrl__discover--img-primary"
                  />

                  <div className="fpvctrl__discover--slider-secondary">
                    <LazyImage src={fpvctrlBack} alt="Fpvctrl feel & fit" />

                    <p className="s-bottom--med">Robust and solid feel</p>
                    <p className="s-bottom--med">Gamepad style ergonomics</p>
                    <p className="s-bottom--med">
                      Built in rechargeable battery
                    </p>
                    <p className="s-bottom--med">
                      Perfect fit for small or big hands
                    </p>
                  </div>
                </div>

                <div className="fpvctrl__discover--slider">
                  <LazyImage
                    src={discoverButtons}
                    alt="Fpvctrl feel & fit"
                    className="fpvctrl__discover--img-primary"
                  />

                  <div className="fpvctrl__discover--slider-secondary">
                    <LazyImage
                      src={fpvctrlDiscoverSide}
                      alt="Fpvctrl feel & fit"
                    />

                    <p className="s-bottom--med">
                      Programmable buttons and switches
                    </p>
                    <p className="s-bottom--med">
                      Every button you need. Not more.
                    </p>
                  </div>
                </div>

                <div className="fpvctrl__discover--slider">
                  <LazyImage
                    src={discoverCharging}
                    alt="Fpvctrl feel & fit"
                    className="fpvctrl__discover--img-primary"
                  />

                  <div className="fpvctrl__discover--slider-secondary">
                    <LazyImage src={fpvctrlFront} alt="Fpvctrl feel & fit" />

                    <p className="s-bottom--med">
                      USB-C for connectivity and charging
                    </p>
                    <p className="s-bottom--med">
                      Low latency Bluetooth radio links for mobile and desktop
                      connectivity
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </section>

          <section id="LearnByPlaying" className="fpvctrl__wrapper--primary">
            <section>
              <div className="wrapper wrapper--narrow">
                <div className="fpvctrl__wrapper--tertiary">
                  <h2 className="fpvctrl__title--primary">
                    Become a better pilot.
                  </h2>

                  <p className="s-bottom--lrg fpvctrl__text--primary">
                    Whether you plan to pilot your first drone or simply enjoy
                    freestyling, the experience can be similar to learning to
                    drive a car. Many elements need to tune in together to get a
                    head start. Getting to know the correct technique can be
                    stressful, time-consuming and financially draining.
                  </p>
                  <p className="fpvctrl__text--primary">
                    What if the winding road to piloting drones was supported by{' '}
                    <a
                      href="https://store.steampowered.com/app/1278060/FPV_SkyDive__FPV_Drone_Simulator/"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseDown={event => event.preventDefault()}
                    >
                      Orqa FPV.Skydive
                    </a>{' '}
                    racing and freestyle simulator optimized for an immersive
                    flying experience? Orqa FPV.Ctrl guides your pilot journey
                    providing a safe space to sharpen your skills, enhance
                    safety and build confidence without the threat of crashes.
                  </p>
                </div>
                <LazyImage
                  src={fpvctrlSide}
                  alt="fpvctrl side"
                  className="fpvctrl__img--absolute"
                />
              </div>
            </section>
          </section>

          <section className="fpvctrl__skydive--wrapper">
            <div className="wrapper wrapper--wide">
              <section className="fpvctrl__splitsection--primary">
                <div className="skydive--content-wrapper">
                  <LazyImage
                    src={logoOrqaSymbol}
                    alt="ORQA logo"
                    className="s-bottom--med"
                    height={50}
                  />
                  <h2 className="t-sans-alt fpvctrl__skydive--title">
                    FPV SkyDive
                  </h2>
                  <p className="fpvctrl__skydive--description">
                    Freestyle simulator for improved drone racing.
                  </p>
                  <div className="fpvctrl__skydive--list">
                    <div className="u-display--flex fpvctrl__skydive--content s-right--lrg">
                      <LazyImage src={iconMap} alt="map icon" />
                      <p>
                        Practice racing skills or enjoy freestyling with
                        selected maps.
                      </p>
                    </div>
                    <div className="u-display--flex fpvctrl__skydive--content">
                      <LazyImage src={iconPc} alt="map pc" />
                      <p>
                        Platform agnostic. Run FPV.SkyDive on PC, Mac or Linux
                        computer.
                      </p>
                    </div>
                  </div>
                  <div className="fpvctrl__skydive--list">
                    <div className="u-display--flex fpvctrl__skydive--content s-right--lrg">
                      <LazyImage src={iconDrone} alt="map pc" />
                      <p>
                        Drone physics optimised for the most realistic flying
                        experience.
                      </p>
                    </div>
                    <div className="u-display--flex fpvctrl__skydive--content">
                      <LazyImage src={iconGear} alt="map pc" />
                      <p>Seamless integration with FPV.Ctrl.</p>
                    </div>
                  </div>
                </div>
                <LazyImage
                  src={skydiveDrone}
                  alt="Skydive drone"
                  className="fpvctrl__img--primary"
                />
              </section>
              <div className="fpvctrl__btn--wrapper">
                <a
                  href="https://bit.ly/fpvskydive20"
                  className="btn btn--primary btn--med"
                  onMouseDown={event => event.preventDefault()}
                >
                  Learn more
                </a>
              </div>
            </div>
          </section>

          <div id="IRCGhost" className="wrapper wrapper--wide fpvctrl__wrapper">
            <section className="fpvctrl__splitsection--secondary">
              <div className="splitsection__content">
                <h2 className="fpvctrl__title--secondary s-bottom--xlrg">
                  ImmersionRC Ghost UberLite.
                </h2>
                <p className="fpvctrl__text--primary s-bottom--med">
                  Achieving progress with Orqa FPV.Ctrl is seamless: once you
                  build confidence to take your skills to the real world, all
                  you need is a radio module that connects to your drone, and
                  you’re ready to fly.
                </p>
                <p className="fpvctrl__text--primary">
                  Orqa has joined forces with Immersion RC to design and build
                  the lightest radio controller module ever: IRC Ghost UberLite.
                </p>
                <a
                  href="https://shop.orqafpv.com/products/immersionrc-immersionrc-ghost-uberlite-tx-module?_pos=1&_sid=1e3d56ac5&_ss=r"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--primary btn--med"
                  style={{ marginTop: '16px' }}
                  onMouseDown={event => event.preventDefault()}
                >
                  Buy now
                </a>
              </div>
              <div className="splitsection__content">
                <LazyImage
                  src={fpvctrlBottom}
                  alt=""
                  className="fpvctrl__img--secondary"
                />
              </div>
            </section>
          </div>

          <section id="MobileApp" className="fpvctrl__splash">
            <div className="wrapper wrapper--narrow">
              <h1 className="fpvctrl__title--primary s-bottom--lrg">
                Dedicated FPV.Ctrl mobile app to manage your settings.
              </h1>
              <p className="fpvctrl__text--primary s-bottom--xlrg">
                Manage settings, update firmware, assign channels or calibrate
                your sticks using FPV.Ctrl mobile application. Intuitive
                interface and user experience.
              </p>
              <div className="fpvctrl__slider--mobile">
                <LazyImage
                  src={fpvctrlPhone}
                  alt="phone"
                  className="fpvctrl__slider--img-absolute"
                />
                <Slider {...settingsMobile}>
                  <div>
                    <LazyImage
                      src={fpvctrlModels}
                      alt=""
                      className="fpvctrl__slider--secondary-img"
                    />
                  </div>

                  <div>
                    <LazyImage
                      src={fpvctrlMenu}
                      alt=""
                      className="fpvctrl__slider--secondary-img"
                    />
                  </div>

                  <div className="">
                    <LazyImage
                      src={fpvctrlInfo}
                      alt=""
                      className="fpvctrl__slider--secondary-img"
                    />
                  </div>
                </Slider>
              </div>
            </div>
            <div className="appstore">
              <a
                href="https://play.google.com/store/apps/details?id=com.orqafpv.fpvctrl&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                <LazyImage
                  src={fpvconnectGoogleplay}
                  alt="Download on the App Store"
                />
              </a>
              <a
                href="https://apps.apple.com/hr/app/orqa-fpv-ctrl/id1562323020?l=hr"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                <LazyImage
                  src={fpvConnectAppstore}
                  alt="Get it on Google Play"
                />
              </a>
            </div>
          </section>

          <section className="fpvctrl__wrapper--secondary">
            <div className="wrapper wrapper--wide">
              <div className="fpvctrl__wrapper--tertiary">
                <h2 className="s-bottom--lrg">
                  Excited about your next drone mission?{' '}
                </h2>
                <p className="s-bottom--xlrg">
                  Start your journey with our new FPV.Ctrl today and take your
                  skills to a whole new level.
                </p>
                <a
                  href="https://shop.orqafpv.com/products/orqa-orqa-fpv-ctrl-radio-controller"
                  className="btn btn--primary btn--med"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  Buy now
                </a>
              </div>
            </div>
          </section>

          <section className="fpvctrl__cta">
            <div className="wrapper wrapper--wide s-bottom--xlrg">
              <section>
                <h2 className="t-beta t-primary  s-bottom--xlrg">
                  Ready to master the art of flying?
                </h2>
                <p className="s-bottom--lrg">
                  Join our FPV.Ctrl newsletter to learn more about pricing and
                  availability.
                </p>
                <div className="fpvctrl__form">
                  <form
                    action="https://share.hsforms.com/1_X4KgDI3TzqxSCRmcUm_0g5d8o5?utm_medium=email&_hsmi=2&_hsenc=p2ANqtz-_tBAGvBxGbCubAei1u9NSPfBfqcv6Jxta_MLXxknhH0U0Z7yVdLBL-6X4aKo5B_2HgtIAVy8e_wYEZ7dF5dqBCnNkZgg&utm_content=2&utm_source=hs_email"
                    method="get"
                    id="embedded-subscribe-form"
                    name="embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                    noValidate
                  >
                    <input
                      type="email"
                      name="email"
                      className="input input--med required email"
                      placeholder="E-mail address"
                      id="email"
                    />
                    <button
                      type="submit"
                      value="Subscribe"
                      name="subscribe"
                      id="embedded-subscribe"
                      className="btn btn--primary btn--med"
                    >
                      Subscribe
                    </button>
                    <div
                      style={{ position: 'absolute', left: '-5000px' }}
                      aria-hidden="true"
                    >
                      <input
                        type="text"
                        name="b_f528df63a6bb5a1820706dad8_4247aa8b2d"
                        tabIndex={-1}
                        value=""
                      />
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </section>
        </section>
      </>
    );
  }
}
