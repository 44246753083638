import React from 'react';

export const VideoTransmission = () => {
  return (
    <section className="dreamX__transmission__section">
      <div className="dreamX__transmission_content--wrapper ">
        <div
          className="dreamX__transmission__text--wrapper responsive__padding"
          style={{
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 2,
            gridColumnEnd: 7,
          }}
        >
          <h2 className="dreamX__title text-center">FOLDABLE PROPELERS</h2>
          <p className="dreamX__body text-center">
            We chose foldable propellers for easy transportation while
            maintaining the performance that pilots demand.
          </p>
        </div>
        <div
          className="dreamX__transmission__text--wrapper responsive__padding"
          style={{
            gridRow: 1 / 2,
            gridRowStart: 1,
            gridRowEnd: 2,
            gridColumnStart: 8,
            gridColumnEnd: 13,
          }}
        >
          <div>
            <h2 className="dreamX__title text-center">CLEAN & TIDY BUILD</h2>
          </div>
          <p className="dreamX__body text-center">
            We spared no effort in making every part of the quadcopter clean and
            sleek.
          </p>
        </div>
        <img
          src={require('main/assets/images/dreamX/Latency & Custome.webp')}
          alt="Pilot spotlight"
          className="dreamX__transmission__image--desktop"
        />
      </div>
      <img
        src={require('main/assets/images/dreamX/FF2_3908.webp')}
        alt="Pilot spotlight"
        className="dreamX__transmission__image--mobile"
      />
    </section>
  );
};
