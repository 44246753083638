import React from 'react';

export const ImmersionRC = () => {
  return (
    <section className="immersionRC__section">
      <div className="immersionRC__content--wrapper responsive__padding">
        <div className="immersionRC__text--wrapper">
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <h3 className="immersionRC__subtitle">VIDEO TRANSMISSION</h3>
            <h2 className="immersionRC__title">BETTER THAN EVER</h2>
          </div>
          <p className="dreamX__body">
            Equipped with ImmersionRC Hybrid Ultimate for exceptional video
            quality and extended range.
          </p>
        </div>
      </div>
      <img
        src={require('main/assets/images/dreamX/Camera 1.webp')}
        alt="Pilot spotlight"
        className="immersionRC__image"
      />
    </section>
  );
};
