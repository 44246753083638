import React from 'react';
import { RichText } from 'prismic-reactjs';
import { Link } from 'react-router-dom';

import { LazyImage } from 'main/components';

import { BlogItem } from '../models';

// todo - add meta tags, OGG meta tags, add link to blog details page, check correct prop types
interface Props {
  post: BlogItem;
}

export const BlogPostCard: React.FC<Props> = ({ post }) => {
  const { title, shortContent, publishedDate, slug, heroImage } = post;

  return (
    <article className="blog-post-card">
      {heroImage && (
        <div className="blog-post-card__image">
          <Link
            to={`/news-details/${slug}`}
            className="t-delta t-primary"
            onMouseDown={event => event.preventDefault()}
          >
            <LazyImage src={heroImage} alt={title} />
          </Link>
        </div>
      )}

      <div className="blog-post-card__info">
        <div className="blog-post-card__heading">
          <Link
            to={`/news-details/${slug}`}
            className="t-delta t-primary"
            onMouseDown={event => event.preventDefault()}
          >
            {title}
          </Link>
        </div>

        <div className="blog-post-card__date">
          <p className="t-zeta o-40">{publishedDate}</p>
        </div>

        <div className="blog-post-card__shortcontent">
          <RichText render={shortContent} />
        </div>
      </div>
    </article>
  );
};
