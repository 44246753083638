import * as React from 'react';

import { SEO } from 'main/components';
import {
  DreamXDiscover,
  // EasyFlying,
  FullControl,
  ImmersionRC,
  ImmersionRCFrequency,
  OrqaCareProgram,
  Video,
  VideoTransmission,
  Wings,
} from 'modules/dreamX';
import { Wings2 } from 'modules/dreamX/components/Wings2';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class DreamX extends React.Component<{}, State> {
  state: State = {
    loading: true,
    isMobile: false,
  };

  render() {
    return (
      <>
        <SEO seoTitle="ORQA - DREAMX" seoDescription="" />
        <section className="dreamX">
          <DreamXDiscover />
          <FullControl />
          {/* <EasyFlying /> */}
          <VideoTransmission />
          <ImmersionRC />
          <Wings />
          <Wings2 />
          <Video />
          <ImmersionRCFrequency />
        </section>
        {/* <div style={{ position: 'relative' }}> */}
        <OrqaCareProgram />
        {/* </div> */}
      </>
    );
  }
}
