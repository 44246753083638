import React from 'react';

export const OrqaCareProgram = () => {
  return (
    <section className="careprogram__section">
      <div className="careprogram__content--wrapper">
        <div className="careprogram__box--wrapper responsive__padding">
          <div className="title__column--gap">
            <p className="dreamX__subtitle">ORQA CARE PROGRAM</p>
            <h2 className="dreamX__title">ORQA Care - JUST SEND IT!</h2>
          </div>
          <p className="dreamX__body">
            No matter how hard you push it - we’ve got you covered. The ORQA
            Care program is designed to keep you flying all year long, no
            questions asked. Whether you're a beginner gaining confidence or a
            seasoned pilot sending it to the limits, fly with zero worries. If
            something breaks, we’ll get you back in the air.
          </p>
          <div>
            <a
              href="https://shop.orqafpv.com/products/orqa-dream-5-rtf-hd-ready"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--primary btn--med"
              onMouseDown={event => event.preventDefault()}
            >
              Buy now
            </a>
          </div>
          <div className="careprogram__image--wrapper">
            <img
              src={require('main/assets/images/dreamX/Angled dron.webp')}
              alt="Pilot spotlight"
              className="careprogram__image--mobile"
            />
          </div>
        </div>
      </div>
    </section>
  );
};
