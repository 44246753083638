import * as React from 'react';
import { useQuery } from 'react-apollo';

import { GET_RESELLERS } from 'main/graphql';
import { LoadingSpinner } from 'main/components';

export const Resellers: React.FC = () => {
  const { data, loading, error } = useQuery(GET_RESELLERS);

  return (
    <section className="page">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="wrapper wrapper--narrow">
          {error && <p>Error...</p>}
          <h1 className="t-beta s-bottom--med">Resellers</h1>
          <div className="page__content">
            <div className="page__table">
              {data &&
                data.allResellers.edges.map((resseler: any) => (
                  <div key="id" className="page__table__item">
                    <div className="page__table__item__heading">
                      <p className="t-eta">
                        {resseler.node.title.map((i: any) => i.text)}
                      </p>
                      <p className="t-zeta o-40">
                        {resseler.node.country.map((i: any) => i.text)}
                      </p>
                    </div>
                    <div className="page__table__item__action">
                      <a
                        href={resseler.node.link_to_store.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn--sml btn--nopadding"
                        onMouseDown={event => event.preventDefault()}
                      >
                        Visit Store
                      </a>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
