import React from 'react';

export const Wings = () => {
  return (
    <section className="wings__section">
      <div className="wings__content--wrapper">
        <div className="wings__text--wrapper responsive__padding">
          <h2 className="wings__title">Dream X HD Ready</h2>
        </div>
        <div className="wings__list--wrapper responsive__padding">
          <div className="wings__list list--A">
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <h3 className="wings__list--caption">FLIGHT CONTROLLER</h3>
              <p className="dreamX__body">ORQA FC2020-F722 HD</p>
              <p className="dreamX__body">STM32F722 processor</p>
              <p className="dreamX__body">MPU-6000 gyro</p>
              <p className="dreamX__body">Integrated Ghost Duo Receiver</p>
            </div>
            {/* <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <h3 className="wings__list--caption">Motors and Propellers </h3>
              <p className="dreamX__body">ORQA RTR 6S</p>
              <p className="dreamX__body">Gemfan Floppy Proppy</p>
            </div> */}
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <h3 className="wings__list--caption">ESC</h3>
              <p className="dreamX__">ORQA ESC2020</p>
              <p className="dreamX__body">
                60 Amp 4-in-1 ESC (4 motor outputs)
              </p>
              <p className="dreamX__body">
                Pre-flashed with BLheli_32 v32.100 (latest version before
                shutdown)
              </p>
              <p className="dreamX__body">Factory conformal coated</p>
            </div>
          </div>
          <div className="wings__list list--B">
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <h3 className="wings__list--caption">Motors and Propellers </h3>
              <p className="dreamX__body">ORQA RTR 6S</p>
              <p className="dreamX__body">Gemfan Floppy Proppy</p>
            </div>
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <h3 className="wings__list--caption">Video Transmission</h3>
              <p className="dreamX__body">
                Compatible with all current HD systems on the market
              </p>
            </div>
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
              }}
            >
              <h3 className="wings__list--caption">RC Link</h3>
              <p className="dreamX__body">Onboard ImmersionRC Ghost 2.4 GHz</p>
              <p className="dreamX__body">
                500Hz R/C link updates (hardware is 1kHz ready)
              </p>
              <p className="dreamX__body">Can be disabled</p>
            </div>
          </div>
        </div>

        <img
          src={require('main/assets/images/dreamX/wing.webp')}
          alt="Pilot spotlight"
          className="wings__image"
        />
      </div>
    </section>
  );
};
