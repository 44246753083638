import React, { useState } from 'react';

import { ConstantInputComponent } from 'main/components/ContactInputComponent';

export const AsdaForm: React.FC = () => {
  const [selectedElement, setSelectedElement] = useState<null | string>(null);
  const [name, setName] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [country, setCountry] = useState<any>('');
  const [company, setCompany] = useState<any>('');
  const [yourRequest, setYourRequest] = useState<any>('');

  const [errors, setErrors] = useState<any>(null);

  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [requestError, setRequestError] = useState<any>(null);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    e.preventDefault();

    const newErrors: { [key: string]: string } = {};

    if (!name) {
      newErrors.name = 'Name field is required';
    }

    if (!email) {
      newErrors.email = 'Email field is required';
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      newErrors.email = 'Value is not a valid email format';
    }

    if (!country) {
      newErrors.country = 'Country field is required';
    }

    if (!company) {
      newErrors.company = 'Company field is required';
    }
    if (!yourRequest) {
      newErrors.yourRequest = 'Your request field is required';
    }
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoading(true);
      try {
        const response = await fetch(
          'https://orqafpvsimulator-license.com/api/v1/asda/contact',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name,
              email,
              country,
              company,
              description: yourRequest,
            }),
          },
        );

        if (response.ok) {
          setData(true);
          setErrors(null);

          setName('');
          setEmail('');
          setCompany('');
          setCountry('');
          setYourRequest('');
        } else {
          setRequestError('Failed to send email');
          setData(null);
        }
      } catch (error) {
        setRequestError('Something went wrong, try again or try again later!');
        setData(null);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <section className="page asdaForm--responsive">
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '25px',
        }}
      >
        <img
          style={{ width: '130px', alignSelf: 'center' }}
          src={require('main/assets/images/defense/Logo-black.png')}
          alt="Pilot spotlight"
        />
      </div>
      <div className="wrapper wrapper--narrow">
        <div
          style={{
            maxWidth: '720px',
            width: '100%',
            textAlign: 'center',
            margin: '0px auto',
          }}
        >
          <h2
            style={{
              maxWidth: '720px',
              fontSize: 'calc(24px + 8 * ((100vw - 320px) / 1000))',
              margin: '0 0 8px 0',
              color: '#FF2238',
              lineHeight: '1.25',
              paddingBottom: '30px',
            }}
          >
            Get in touch
          </h2>
          {data && (
            <div style={{ textAlign: 'left', marginBottom: '15px' }}>
              <p
                style={{
                  fontSize: '14px',
                  color: requestError ? 'rgb(255, 34, 56)' : '#07bc0c',
                }}
              >
                {requestError
                  ? 'Something went wrong, try again or try again later!'
                  : 'Email Succesfully sent!'}
              </p>
            </div>
          )}
          <form
            id="asda-form"
            style={{
              maxWidth: '720px',
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              gap: '25px',
            }}
          >
            <div className="asda__form-wrapper">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px',
                }}
              >
                <ConstantInputComponent
                  setter={setName}
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  name={'Name'}
                  errors={errors}
                  getter={name}
                />
                <ConstantInputComponent
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  name={'Email'}
                  setter={setEmail}
                  errors={errors}
                  getter={email}
                />
                <ConstantInputComponent
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  name={'Country'}
                  setter={setCountry}
                  errors={errors}
                  getter={country}
                />
                <ConstantInputComponent
                  setSelectedElement={setSelectedElement}
                  selectedElement={selectedElement}
                  name={'Company'}
                  setter={setCompany}
                  errors={errors}
                  getter={company}
                />
              </div>
              <div
                onFocus={() => {
                  setSelectedElement('Your request');
                }}
                onBlur={() => {
                  setSelectedElement(null);
                }}
                style={{
                  display: 'block',
                  position: 'relative',
                  minWidth: '100%',
                }}
              >
                <label
                  className={
                    selectedElement === 'Your request' || yourRequest
                      ? `asda-label asda-label--active`
                      : `asda-label`
                  }
                >
                  Your request*
                </label>
                <span
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <textarea
                    value={yourRequest}
                    onChange={value => {
                      if (!value) {
                        setYourRequest(null);
                      }
                      setYourRequest(value.currentTarget.value);
                    }}
                    className={'asda-input asda-textarea'}
                  />
                  {errors?.yourRequest && (
                    <p className="asda__error__message">{errors.yourRequest}</p>
                  )}
                </span>
              </div>
            </div>
            <div
              style={{
                maxWidth: '720px',
                display: 'block',
                textAlign: 'right',
              }}
            >
              <button
                disabled={loading}
                className="asda__submit-button"
                onClick={handleSubmit}
              >
                <>
                  {loading ? (
                    <div className=" asda__button-spinner--wrapper">
                      <div className="asda__button-spinner" />
                    </div>
                  ) : (
                    <p>SEND</p>
                  )}
                </>
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
