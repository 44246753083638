import * as React from 'react';

import { LoadingSpinner, SEO } from 'main/components';

import iconLocation from '../../../main/assets/icons/location.png';
import iconClock from '../../../main/assets/icons/clock.png';

interface JobListing {
  uuid: string;
  title: string;
  slug: string;
  shortDescription: string;
  deadline: string | null;
  visibilityState: string;
  isAcceptingApplications: boolean;
  coverPhotoUrl: string;
  publishState: string;
  position: number;
  createdByUuid: string;
  createdAt: string;
  completedAt: string | null;
  valueCustomFields: any[];
}

interface State {
  jobListings: JobListing[];
  loading: boolean;
  error: string | null;
}

export class Jobs extends React.Component<{}, State> {
  state: State = {
    jobListings: [],
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.fetchJobListings();
  }

  fetchJobListings = async () => {
    const apiKey = process.env.REACT_APP_PONTA_API_KEY || '';
    try {
      const jobListingResponse = await fetch(
        'https://api.pontahr.com/v1/job-listings',
        {
          headers: {
            'x-api-key': apiKey,
          },
        },
      );
      if (!jobListingResponse.ok) {
        throw new Error('Failed to fetch job listings');
      }
      const jobListing = await jobListingResponse.json();

      const sortedJobs: JobListing[] = jobListing.sort(
        (a: JobListing, b: JobListing) => a.position - b.position,
      );

      this.setState({
        jobListings: sortedJobs,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error: error.message,
        loading: false,
      });
    }
  };

  renderJobListings = () => {
    const { jobListings } = this.state;

    return jobListings.map((job, index) => (
      <React.Fragment key={job.uuid}>
        <hr className="jobs-hr" />
        <div className="job-card">
          <div className="job-details">
            <h4 className="job-title">
              <a
                href={`https://orqafpv.pontahr.com/listings/${job.slug}`}
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                {job.title}
              </a>
            </h4>
            <p className="job-description">{job.shortDescription}</p>
            <div className="chips">
              {job.title !== 'Student Opportunities' && (
                <div className="chip">
                  <img src={iconClock} className="chip-icon" alt="Clock" />
                  <span>Full-time</span>
                </div>
              )}
              <div className="chip-container">
                <div className="chip">
                  <img
                    src={iconLocation}
                    className="chip-icon"
                    alt="Location"
                  />
                  <span>Osijek</span>
                </div>
                <div className="chip">
                  <img
                    src={iconLocation}
                    className="chip-icon"
                    alt="Location"
                  />
                  <span>Zagreb</span>
                </div>
              </div>
            </div>
          </div>

          <div className="job-action">
            <a
              href={`https://orqafpv.pontahr.com/listings/${job.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className="apply-link"
              onMouseDown={event => event.preventDefault()}
            >
              Apply ↗
            </a>
          </div>
        </div>
        {index === jobListings.length - 1 && <hr className="jobs-hr" />}
      </React.Fragment>
    ));
  };

  render() {
    const { loading, error } = this.state;

    if (loading) {
      return (
        <div className="wrapper wrapper--ultranarrow">
          <LoadingSpinner />
        </div>
      );
    }

    return (
      <>
        <SEO seoTitle="ORQA - Jobs" />
        <section className="openpositions" style={{ marginRight: 0 }}>
          <section className="splash splash-sm-pb">
            <div
              className="wrapper wrapper--narrow"
              style={{ textAlign: 'center', margin: 'auto', marginTop: '1rem' }}
            >
              <p className="t-beta s-bottom--med" style={{ margin: '2rem' }}>
                Be Part Of Our Mission
              </p>
              <p
                className="t-epsilon s-bottom--sml"
                style={{ textAlign: 'start' }}
              >
                {' '}
                We are looking for passionate employees to help us achieve our
                mission: to become the world's leading technology provider for
                First Person View (FPV) and advanced Remote Reality.
              </p>
            </div>
          </section>

          {error && (
            <p
              className="t-delta t-error"
              style={{ color: '#ee2737', textAlign: 'center' }}
            >
              Error: {error}
            </p>
          )}
          {!loading && !error && this.renderJobListings()}

          {!error && (
            <div
              className="wrapper wrapper--ultranarrow"
              style={{ marginTop: '4rem' }}
            >
              <p
                className="t-epsilon s-bottom--sml"
                style={{
                  textAlign: 'center',
                  marginTop: '99.3px',
                }}
              >
                If you wish to be a part of a dynamic and creative working
                environment, Orqa's diverse team is definitely right for you!
              </p>
            </div>
          )}
        </section>
      </>
    );
  }
}
