import React from 'react';

import { SEO } from 'main/components';

export const EUInovationVouchers: React.FC = () => {
  // const contactHref = 'mailto:dragan@orqafpv.com';

  return (
    <>
      <SEO seoTitle="ORQA - Razvojna ispitivanja na dronovima Orqa Microdron i Orqa Drone Soccer" />
      <section className="projects">
        <header className="projects__header">
          <p className="t-zeta t-upperext  t-nowrap o-40 s-bottom--sml">
            EU Projects
          </p>
          <h1 className="t-beta t-primary t-center s-bottom--sml">
            Razvojna ispitivanja na dronovima Orqa Microdron i Orqa Drone Soccer
          </h1>
          <p className="t-zeta t-center o-40 s-bottom--med">
            (Kod projekta:PK.1.1.06.0015)
          </p>
        </header>
        <div className="projects__sponsors">
          <img
            src={require('main/assets/images/Amblem.webp')}
            alt="ESIF logo"
            className="projects__sponsors__img"
          />
        </div>
        <div className="wrapper wrapper--ultranarrow">
          <h5 className="s-bottom--med">Opis projekta:</h5>
          <p className="t-delta s-bottom--med">
            Prijavitelj se bavi razvojem i prodajem vrlo kompleksne tehnologije
            – FPV opreme koja se najčešće koristi u svrhe upravljanja
            bespilotnim letjelicama – dronovima.
          </p>
          <p className="t-delta s-bottom--med">
            Provedbom potrebnih ispitivanja na dvije nove vrste drona, Orqa
            Microdona i Orqa Drone Soccer-a, doprinijet će se njihovom razvoju i
            plasiranju na tržište.
          </p>
          <p className="t-delta s-bottom--med">
            Rezultat: Ispunjenje uvjeta za ishodovanje CE certifikata i
            stavljanja dva nova proizvoda na tržište
          </p>
          <p className="s-bottom--med">
            Ukupna vrijednost projekta: 9.300,00 €
          </p>
          <p className="s-bottom--med">
            Iznos bespovratnih sredstava: 7.905,00 €
          </p>
          <p className="s-bottom--med">
            Početak razdoblja provedbe projekta: 19.08.2024.
          </p>
          <p className="s-bottom--med">
            Kraj razdoblja provedbe projekta: 17.12.2024.
          </p>
          <p className="t-zeta o-60">
            Sadržaj je isključiva odgovornost tvrtke Orqa d.o.o. i ni pod kojim
            uvjetima ne odražava stav Europske unije.
          </p>
        </div>
      </section>
    </>
  );
};
