import React from 'react';

export const DreamXDiscover = () => {
  return (
    <>
      <section className="dreamX__description__content--wrapper dreamX__description__content--template-3 responsive__padding">
        <div className="dreamX__text--wrapper">
          <div className="dreamX__description__title__subtitle">
            <h3 className="dreamX__subtitle">BNF ANALOG AND HD READY</h3>
            <h1 className="dreamX__discover__title">DREAM X</h1>
          </div>
          <div className="dreamX__discover__grid--merge">
            <p className="dreamX__body">
              Orqa’s newest freestyle masterpiece. The flight experience you’ve
              been dreaming of. Unrivaled performance, precision, and
              craftsmanship turn your vision into a reality.
            </p>

            <div className="dreamX__discover--button">
              <a
                href="https://shop.orqafpv.com/products/orqa-dream-5-rtf-hd-ready"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--primary btn--med"
                onMouseDown={event => event.preventDefault()}
              >
                buy now
              </a>
            </div>
          </div>
        </div>
        <img
          src={require('main/assets/images/dreamX/DreamX_dron 1.webp')}
          alt="Pilot spotlight"
          className="dreamX__discover__image--desktop"
        />
      </section>
    </>
  );
};
