import React from 'react';

import { ScrollDownIndicator } from 'modules/fpvAce';

import mobile from '../../../main/assets/images/defense/image.png';
import mobileSecond from '../../../main/assets/images/defense/image 13.png';
import mobileThird from '../../../main/assets/images/defense/image 12.png';
import mobileFourth from '../../../main/assets/images/defense/image 14.png';

export const DefenseMobile = () => {
  return (
    <div className="defense-mobile">
      <div className="defense-mobile-sticky">
        <p>Rugged tactical defense solution</p>
      </div>
      <div className="defense-first-hero">
        <img src={mobile} />
        <div className="defense-first-container">
          <h2 className="defense-first-title">MRM2 - 10F</h2>
          <p className="defense-first-description">
            MRM2-10F is a light-weight multi-role First Person View (FPV)
            multicopter UAV, designed and built for versatility, effectiveness,
            and cost efficiency with unparalleled EW resilience.
          </p>
        </div>
        <ScrollDownIndicator screenSize={600} />
      </div>
      <div className="defense-second-hero">
        <img src={mobileSecond} />
        <div className="defense-second-container">
          <h2>GCS-1 - IRONghost ground station</h2>
          <p>
            Orqa GCS-1 Ground Control System is a complete solution for tactical
            FPV UAS operations. It contains everything that is required for safe
            and reliable FPV mission deployments.
          </p>
        </div>
      </div>
      <div className="defense-third-hero">
        {' '}
        <img src={mobileThird} />
        <div className="defense-third-container">
          <h2>Orqa Tac.Ctrl</h2>
          <p>
            Tac.Ctrl is a tactical remote controller for First Person View (FPV)
            UAVs, designed for operator safety and robustly built for reliable
            and precise operation in adverse field environments.
          </p>
        </div>
      </div>
      <div className="defense-fourth-hero">
        {' '}
        <img src={mobileFourth} />
        <div className="defense-fourth-container">
          <h2>Orqa FPV.Pro</h2>
          <p>
            Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and
            ease of use in the field. With large and tactile glove-operable
            switches, robust and clean design, and simplified operation, the
            FPV.Pro has been designed for high-stakes missions. 
          </p>
        </div>
        <a
          href="https://orqafpv.com/asda/contact"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--secondary btn--sml defense-cta-button"
          onMouseDown={event => event.preventDefault()}
        >
          get in touch
        </a>
      </div>
    </div>
  );
};
