import React from 'react';

import { SEO } from 'main/components';

export const EUInternationalization: React.FC = () => {
  const contactHref = 'mailto:dragan@orqafpv.com';

  return (
    <>
      <SEO seoTitle="ORQA - EU Internationalization" />
      <section className="projects">
        <header className="projects__header">
          <p className="t-zeta t-upperext  t-nowrap o-40 s-bottom--sml">
            EU Projects
          </p>
          <h1 className="t-beta t-primary t-center s-bottom--sml">
            Internacionalizacija rješenja ORQA
          </h1>
          <p className="t-zeta t-center o-40 s-bottom--med">
            (Kod projekta: KK.03.2.1.16.0170)
          </p>
        </header>
        <div className="projects__sponsors">
          <img
            src={require('main/assets/images/ESIF-logo.webp')}
            alt="ESIF logo"
            className="projects__sponsors__img"
          />
          <img
            src={require('main/assets/images/OPKK-logo.webp')}
            alt="ESIF logo"
            className="projects__sponsors__img"
          />
          <div className="projects__sponsors__img t-center">
            <img
              src={require('main/assets/images/Eu-flag.webp')}
              alt="Europian union flag"
              className="projects__sponsors__img--secondary"
            />
            <p className="t-tiny s-top--med">Europska Unija</p>
            <p className="t-tiny s-top--sml">Zajedno do fondova EU</p>
          </div>
        </div>
        <div className="wrapper wrapper--ultranarrow">
          <p className="t-zeta o-60 s-bottom--xlrg">
            Projekt je sufinancirala Europska unija iz Europskog fonda za
            regionalni razvoj - Operativni program „Konkurentnost i kohezija“{' '}
          </p>
          <h5 className="s-bottom--med">Opis projekta:</h5>
          <p className="t-delta s-bottom--med">
            Prijavitelj je u 2018. godini, nakon višemjesečnog ulaganja u
            istraživanje i razvoj, razvio vlastiti proizvod - video naočale za
            prijenos video signala niske latencije.
          </p>
          <p className="t-delta s-bottom--med">
            S obzirom na razinu inovativnosti predmetnih tehnologija za prikaz i
            prijenos video signala, u kratkoročnom planu prijavitelj traži
            prilike za zauzimanje značajnog udjela na međunarodnom globalnom
            tržištu tehnologija udaljene stvarnosti.
          </p>
          <p className="t-delta s-bottom--xlrg">
            Navedeno planira ostvariti predstavljanjem proizvoda na
            specijaliziranim međunarodnim sajmovima u Francuskoj, SAD-u i
            Japanu.
          </p>

          <h5 className="s-bottom--med">
            Ciljevi i očekivani rezultati projekta:
          </h5>
          <ol className="projects__list s-bottom--xlrg">
            <li className="s-bottom--sml">
              Povećanje međunarodne konkurentnosti
            </li>
            <li className="s-bottom--sml">Povećanje prihoda od prodaje</li>
            <li className="s-bottom--sml">Povećanje prihoda od izvoza</li>
          </ol>

          <p className="s-bottom--med">
            Ukupna vrijednost projekta: 226.956,00 HRK
          </p>
          <p className="s-bottom--med">
            Iznos bespovratnih sredstava: 191.777,81 HRK
          </p>
          <p className="s-bottom--med">
            Razdoblje provedbe projekta: 29.4.2019. – 29.10.2022.
          </p>
          <p className="s-bottom--med">
            Kontakt osoba za više informacija:{' '}
            <a href={contactHref} onMouseDown={event => event.preventDefault()}>
              Dragan Kovačević
            </a>
          </p>
          <p className="s-bottom--med">Dodatne poveznice:</p>
          <ul className="s-bottom--lrg">
            <li className="s-bottom--sml">
              <a
                href="http://strukturnifondovi.hr/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                http://strukturnifondovi.hr/
              </a>
            </li>
            <li>
              <a
                href="http://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                http://strukturnifondovi.hr/eu-fondovi/esi-fondovi-2014-2020/op-konkurentnost-i-kohezija/
              </a>
            </li>
          </ul>

          <p className="t-zeta o-60">
            Sadržaj je isključiva odgovornost tvrtke Orqa d.o.o. i ni pod kojim
            uvjetima ne odražava stav Europske unije.
          </p>
        </div>
      </section>
    </>
  );
};
