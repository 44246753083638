import React from 'react';

import facebookIcon from '../../../main/assets/images/icon-social-facebook.svg';
import instagramIcon from '../../../main/assets/images/icon-social-instagram.svg';
import youtubeIcon from '../../../main/assets/images/icon-social-youtube.svg';
import tiktokIcon from '../../../main/assets/images/icon-social-tiktok.svg';

interface Props {
  icon: string;
  link: string;
}

export const PilotItemSocial: React.FC<Props> = ({ icon, link }) => {
  const icons = {
    fb: facebookIcon,
    ig: instagramIcon,
    tikTok: tiktokIcon,
    yt: youtubeIcon,
  };

  return (
    <a
      key={icon}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="pilot-item__socials-link"
      onMouseDown={event => event.preventDefault()}
    >
      <img src={icons[icon]} alt="Social icon" className="header__icon" />
    </a>
  );
};
