import React from 'react';
import { motion } from 'framer-motion';

export const Hero = () => (
  <header className="fpvpilot__header">
    <div className="fpvpilot__header--wrapper">
      <motion.h2
        initial={{ opacity: 0, y: '20px' }}
        animate={{
          opacity: 1,
          y: '0',
        }}
        transition={{
          ease: 'easeOut',
          duration: 0.3,
        }}
        className="fpvpilot__header__title--secondary"
      >
        Evolved. Digital Ready.
      </motion.h2>
      <motion.h1
        initial={{ opacity: 0, y: '30px' }}
        animate={{
          opacity: 1,
          y: '0',
        }}
        transition={{
          ease: 'easeOut',
          duration: 0.5,
        }}
        className="fpvpilot__header__title--primary"
      >
        FPV.One Pilot
      </motion.h1>
      <motion.p
        initial={{ opacity: 0, y: '40px' }}
        animate={{
          opacity: 1,
          y: '0',
        }}
        transition={{
          ease: 'easeOut',
          delay: 0.2,
          duration: 0.4,
        }}
        className="fpvpilot__header--description s-bottom--lrg"
      >
        Most advanced FPV goggles for immersive drone flying experiences.
      </motion.p>
      <motion.div
        initial={{ opacity: 0, y: '50px' }}
        animate={{
          opacity: 1,
          y: '0',
        }}
        transition={{
          ease: 'easeOut',
          delay: 0.4,
          duration: 0.4,
        }}
      >
        <a
          href="https://shop.orqafpv.com/products/orqa-orqa-fpv-one-pilot"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--primary btn--med s-bottom--xlrg"
          onMouseDown={event => event.preventDefault()}
        >
          Buy now
        </a>
      </motion.div>
    </div>
    <motion.img
      initial={{ opacity: 0, y: '60px' }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        ease: 'easeOut',
        delay: 0.4,
        duration: 0.6,
      }}
      src={require('main/assets/images/fpvonepilot/ORQA_Pilot_MKII.webp')}
      alt="FPV ONE PILOT"
      className="fpvpilot__header--image"
    />
  </header>
);
