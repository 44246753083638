import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';
import React from 'react';

export const Video = () => {
  return (
    <InView triggerOnce={false}>
      {({ ref, inView }) => (
        <motion.video
          ref={ref}
          src="https://res.cloudinary.com/dnocms3a6/video/upload/v1742372875/gynoaqfrasgin52eevae.mp4"
          className="fpvpilot__video dreamX__video"
          initial={{ opacity: 0.6, width: '70%' }}
          animate={{
            opacity: inView ? 1 : 0.6,
            width: inView ? '100%' : '70%',
          }}
          transition={{ ease: 'easeOut', duration: 0.6, delay: 0.3 }}
          muted
          autoPlay
          loop
          playsInline
        />
      )}
    </InView>
  );
};
