import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import { LazyImage } from 'main/components';

export const PilotNewsletter: React.FC = () => (
  <>
    <InView triggerOnce={true}>
      {({ ref, inView }) => (
        <motion.div className="fpvpilot__connect">
          <motion.h2
            initial={{ opacity: 0, y: '30px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '30px' }}
            transition={{ ease: 'circOut', duration: 0.3 }}
            className="fpvpilot__connect--title"
          >
            Ready to connect?
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: '30px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '30px' }}
            transition={{ ease: 'circOut', duration: 0.3, delay: 0.3 }}
            className="fpvpilot__connect--description"
          >
            Broadcast, update, connect with other FPV pilots and share your
            content with a few clicks.
          </motion.p>
          <motion.div
            initial={{ opacity: 0, y: '30px' }}
            animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '30px' }}
            transition={{ ease: 'circOut', duration: 0.3, delay: 0.6 }}
          >
            <Link
              to="/fpvconnect"
              className="btn btn--primary btn--med"
              style={{ marginTop: '40px' }}
              onMouseDown={event => event.preventDefault()}
            >
              Connect
            </Link>
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: inView ? 1 : 0 }}
            transition={{ ease: 'circOut', duration: 0.3, delay: 0.9 }}
            className="appstore fpvpilot__connect--appstore"
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.orqafpv.orqaapp&hl=en&gl=US"
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={event => event.preventDefault()}
            >
              <LazyImage
                src={require('main/assets/pages/fpvconnect/fpvconnect-googleplay.webp')}
                alt="Download on the App Store"
              />
            </a>
            <a
              href="https://apps.apple.com/hr/app/orqa-fpv-connect/id1503651087?l=hr"
              target="_blank"
              rel="noopener noreferrer"
              onMouseDown={event => event.preventDefault()}
            >
              <LazyImage
                src={require('main/assets/pages/fpvconnect/fpvconnect-appstore.webp')}
                alt="Get it on Google Play"
              />
            </a>
          </motion.div>
          <LazyImage
            src={require('main/assets/images/fpvonepilot/fpv-connect.webp')}
            alt="Fpv get app"
          />
        </motion.div>
      )}
    </InView>

    <section className="fpvctrl__cta">
      <div className="wrapper wrapper--wide s-bottom--xlrg">
        <section>
          <h2 className="t-beta t-primary  s-bottom--xlrg">
            Ready to master the art of flying?
          </h2>
          <p className="s-bottom--lrg">
            Subscribe to our newsletter and find all the latest info on item
            availability, pricing, and hot new deals!
          </p>
          <div className="fpvctrl__form">
            <form
              action="https://share.hsforms.com/1_X4KgDI3TzqxSCRmcUm_0g5d8o5?utm_medium=email&_hsmi=2&_hsenc=p2ANqtz-_tBAGvBxGbCubAei1u9NSPfBfqcv6Jxta_MLXxknhH0U0Z7yVdLBL-6X4aKo5B_2HgtIAVy8e_wYEZ7dF5dqBCnNkZgg&utm_content=2&utm_source=hs_email"
              method="get"
              id="embedded-subscribe-form"
              name="embedded-subscribe-form"
              className="validate"
              target="_blank"
              noValidate
            >
              <input
                type="email"
                name="email"
                className="input input--med required email"
                placeholder="E-mail address"
                id="email"
              />
              <button
                type="submit"
                value="Subscribe"
                name="subscribe"
                id="embedded-subscribe"
                className="btn btn--primary btn--med"
              >
                Subscribe
              </button>
              <div
                style={{ position: 'absolute', left: '-5000px' }}
                aria-hidden="true"
              >
                <input
                  type="text"
                  name="b_f528df63a6bb5a1820706dad8_4247aa8b2d"
                  tabIndex={-1}
                  value=""
                />
              </div>
            </form>
          </div>
        </section>
      </div>
    </section>
  </>
);
