import { parse } from 'querystring';

import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { Navigation } from 'main/components';

import CookiesBanner from './CookiesBanner';

import orqaLogo from '../assets/images/orqa-logo.png';
import facebookIcon from '../assets/images/icon-social-facebook.svg';
import instagramIcon from '../assets/images/icon-social-instagram.svg';
import youtubeIcon from '../assets/images/icon-social-youtube.svg';
import xIcon from '../assets/images/icon-social-x.svg';

const Layout: React.FC = ({ children }) => {
  const { search } = useLocation();

  const { seoredirect } = parse(search.slice(1));
  if (seoredirect) {
    return <Redirect to={seoredirect as string} />;
  }

  return (
    <div className="container">
      <div className="container__overlay" />

      <header className="header">
        <div className="header__top">
          <div className="header__branding">
            <Link to="/" onMouseDown={event => event.preventDefault()}>
              <img src={orqaLogo} alt="ORQA" />
            </Link>
          </div>
          <a
            href="https://shop.orqafpv.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--secondary btn--sml"
            onMouseDown={event => event.preventDefault()}
          >
            Shop
          </a>
        </div>
      </header>

      <div className="header__bottom">
        <div className="header__bottom__wrapper">
          <Navigation />
          <div className="social__wrapper__mobile">
            <div className="social">
              <a
                href="https://www.facebook.com/OrqaFPV"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                <img
                  src={facebookIcon}
                  alt="ORQA on Facebook"
                  className="header__icon"
                />
              </a>
              <a
                href="https://www.instagram.com/orqafpv/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                <img
                  src={instagramIcon}
                  alt="ORQA on Instagram"
                  className="header__icon"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCGPZJ5j16slPTObilo8viwg/featured"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                <img
                  src={youtubeIcon}
                  alt="ORQA on Youtube"
                  className="header__icon"
                />
              </a>
              <a
                href="https://twitter.com/OrqaFPV"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                <img
                  src={xIcon}
                  alt="ORQA on Twitter"
                  className="header__icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="container__content">
        <main className="container__children">{children}</main>
        <CookiesBanner />
      </div>
      <div className="container__lightoverlay" />
    </div>
  );
};

export default Layout;
