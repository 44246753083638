import React from 'react';

interface InputInterface {
  selectedElement: any; // You can replace `any` with the actual type later
  setSelectedElement: React.Dispatch<React.SetStateAction<any>>; // Also replace `any` if you know the type
  name: any;
  setter: any;
  errors: any;
  getter: any;
}

export const ConstantInputComponent: React.FC<InputInterface> = ({
  selectedElement,
  setSelectedElement,
  name,
  setter,
  getter,
  errors,
}) => {
  return (
    <div
      onFocus={() => {
        setSelectedElement(name);
      }}
      onBlur={() => {
        setSelectedElement(null);
      }}
      style={{
        display: 'block',
        position: 'relative',
        minWidth: '100%',
      }}
    >
      <label
        className={
          selectedElement === name || getter
            ? `asda-label asda-label--active`
            : `asda-label`
        }
      >
        {name}*
      </label>
      <span>
        <input
          id={`${name}-input`}
          value={getter}
          onChange={value => {
            if (!value) {
              setter(null);
            }
            setter(value.currentTarget.value);
          }}
          className="asda-input"
        />
        {errors?.[name.toLowerCase()] && (
          <p className="asda__error__message">{errors?.[name.toLowerCase()]}</p>
        )}
      </span>
    </div>
  );
};
