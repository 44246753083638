import { Pilot } from '../models';

export const pilotList: Pilot[] = [
  {
    name: 'Benjamin Heimisson',
    handle: 'BenjaminFPV',
    country: 'Iceland',
    imgSrc: 'benjamin.webp',
    socials: {
      fb: 'https://www.facebook.com/benjaminheimis',
      ig: 'https://www.instagram.com/benjamin.fpv/',
      tikTok:
        'https://www.tiktok.com/@benjamin_fpv?is_from_webapp=1&sender_device=pc',
    },
  },
  {
    name: 'Charles Rinfret',
    handle: 'Paco',
    country: 'Canada',
    imgSrc: 'paco.webp',
    socials: {
      fb: 'https://www.facebook.com/charles.rinfret',
      ig: 'https://www.instagram.com/thepacofpv/',
      yt: 'https://www.youtube.com/@pacofpv8517',
    },
  },
  {
    name: 'Noah Pitzer',
    handle: 'CustomRCmods',
    country: 'USA',
    imgSrc: 'pitzer.webp',
    socials: {
      fb: 'https://www.facebook.com/noah.pitzer.712',
      ig: 'https://instagram.com/customrcmodsofficial',
      tikTok:
        'https://www.tiktok.com/@benjamin_fpv?is_from_webapp=1&sender_device=pc',
    },
  },
  {
    name: 'Peter Rudman',
    handle: 'NightwingFPV',
    country: 'USA',
    imgSrc: 'nightwing.webp',
    socials: {
      fb: 'https://www.facebook.com/petethefeet123',
      ig: 'https://www.instagram.com/nightwingfpv/',
    },
  },
  {
    name: 'Svanur Gabriele Monaco',
    handle: 'SvanurFPV',
    country: 'Iceland',
    imgSrc: 'svanur.webp',
    socials: {
      fb: 'https://www.facebook.com/svanur.gabriele',
      ig: 'https://www.instagram.com/svanurfpv/',
      tikTok:
        'https://www.tiktok.com/@svanur_fpv?is_from_webapp=1&sender_device=pc',
    },
  },
];
