import { Expo, TimelineLite, TweenMax } from 'gsap';
import { RichText } from 'prismic-reactjs';
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import ScrollMagic from 'scrollmagic';
import { AnimatePresence } from 'framer-motion';

import { LoadingSpinner, SEO } from 'main/components';
import { LazyImage } from 'main/components/LazyImage';
import { GET_BLOG_POSTS } from 'main/graphql';
import { BlogItem } from 'modules';
import client from 'modules/prismic/clientCfg';
import { ManifestoModal } from 'main/components/ManifestoModal';

const scrollMagicController = new ScrollMagic.Controller();

interface State {
  loading: boolean;
  isMobile: boolean;
  latestNews: BlogItem[];
  newsLoading: boolean;
  isModalOpen: boolean;
  newsError?: string;
}

class Homepage extends React.Component<RouteComponentProps, State> {
  state: State = {
    loading: true,
    isMobile: false,
    latestNews: [],
    newsLoading: true,
    newsError: undefined,
    isModalOpen: true,
  };

  apolloClient = client;

  componentDidMount() {
    this.getLatestNews();
    // const isMobile = window.innerWidth <= 991;

    // Newsletter animation
    // Newsletter animation
    // Newsletter animation
    const animateNewsletter = new TimelineLite();
    animateNewsletter.pause();

    animateNewsletter
      .add(
        TweenMax.staggerFromTo(
          '#newsletter .newsletter__heading *',
          1.5,
          {
            opacity: 0,
            y: 60,
          },
          {
            opacity: 1,
            y: 0,
            ease: Expo.easeOut,
          },
          0.2,
        ),
      )
      .fromTo(
        '#newsletter .newsletter__form',
        2,
        {
          opacity: 0,
          y: 60,
        },
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        '-=1',
      )
      .fromTo(
        '.footer__line',
        0.6,
        {
          width: 0,
        },
        {
          width: '100%',
          ease: Expo.easeOut,
        },
        '-=1.5',
      )
      .staggerFromTo(
        '.footer__container > section',
        1.5,
        {
          opacity: 0,
          y: 60,
        },
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        0.2,
        '-=1',
      )
      .fromTo(
        '.footer__zicer',
        1.5,
        {
          opacity: 0,
          y: 60,
        },
        {
          opacity: 1,
          y: 0,
          ease: Expo.easeOut,
        },
        '-=1',
      );

    new ScrollMagic.Scene({
      triggerElement: '#newsletter',
      triggerHook: 0.6,
    })
      .on('enter', () => {
        animateNewsletter.play().timeScale(1);
      })
      .on('leave', () => {
        animateNewsletter.reverse().timeScale(2);
      })
      .addTo(scrollMagicController);
  }

  componentDidUpdate(prevProps: RouteComponentProps) {
    if (
      this.props.location.pathname !== prevProps.location.pathname &&
      this.props.location.pathname === '/'
    ) {
      this.setState({ isModalOpen: true });
    }
  }

  getLatestNews = () => {
    this.apolloClient
      .query({
        query: GET_BLOG_POSTS,
      })
      .then(response => {
        this.destructureBlogPosts(response.data, []);
      })
      .catch(() => {
        this.setState({
          newsError: 'Something went wrong...',
          newsLoading: false,
        });
      });
  };

  destructureBlogPosts = (data: any, posts: BlogItem[]) => {
    data.allBlog_posts.edges.forEach((post: any) => {
      if (post.node.category.category === 'News') {
        const blogItem: BlogItem = {
          title: post.node.title[0]?.text,
          shortContent: post.node?.short_content,
          longContent: post.node?.long_content,
          author: post.node?.author,
          publishedDate: post.node?.published_date,
          slug: post.node._meta.uid,
          category: post.node.category?.category,
          heroImage: post.node.hero_image?.url,
        };
        posts.push(blogItem);
      }
    });
    this.setState({
      latestNews: posts.slice(0, 4),
      newsLoading: false,
    });
  };

  render() {
    const { loading } = this.state;

    const settings: Settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplaySpeed: 5000,
      arrows: false,
      appendDots: dots => (
        <>
          <div className="home__slider--dots">
            <ul className="slick-dots">{dots}</ul>
          </div>
        </>
      ),
    };

    return (
      <section>
        <SEO
          seoTitle="ORQA - Never stop flying."
          seoDescription="Orqa d.o.o. was founded in 2018. It is a company whose mission is to become the World’s number one technology provider for First Person View (FPV) and advanced Remote Reality (RR) applications by developing enabling technology for next-generation vision systems."
        />
        {loading && (
          <div className="loading">
            <div className="loading__graphic">
              <svg width="225" height="94" xmlns="http://www.w3.org/2000/svg">
                <g
                  fill="none"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  stroke="#FF2238"
                  className="loading__svg"
                >
                  <path d="M84.364 2.125L112.5 37.75 5.375 25.6l78.99-23.475zM15.875 72.982L92.3 80.375l20.325-18m-20.5 18h20.25m-20.125 0L36.5 93" />
                  <path d="M35.467 92.75l-19.64-19.734L5.375 25.5 112.5 62.369 12.442 73.389M140.636 2.124L112.5 37.75 219.625 25.6l-78.99-23.475z" />
                  <path d="M209.25 72.962l-76.425 7.413-20.325-18.05v-24.7m20.25 42.75H112.5m20.25 0L188.5 93" />
                  <path d="M189.533 92.75l19.64-19.734L219.625 25.5 112.5 62.369l100.058 11.02" />
                  <path d="M112.875 2.441L212.45 1 224 40.337l-12.737 37.09L189.138 93l-55.136-1.987-13.792-6.637-7.335.521" />
                  <path d="M112.9 2.441L12.552 1 1 40.337l12.739 37.09L35.867 93l55.142-1.987 13.794-6.637 8.197.56" />
                </g>
              </svg>
            </div>
            <h1 className="loading__title">Plase stand by.</h1>
          </div>
        )}

        <AnimatePresence>
          {this.state.isModalOpen && (
            <ManifestoModal
              onClose={() =>
                this.setState({
                  isModalOpen: false,
                })
              }
            />
          )}
        </AnimatePresence>
        <section className="home__hero--slider">
          <Slider {...settings}>
            <section className="home__hero">
              <div className="home__hero--wrapper">
                <div className="home__hero--content">
                  <div className="home__hero__content--wrapper">
                    <h2 className="home__hero--subtitle">FPV.One</h2>
                    <h1 className="home__hero--title">Pilot</h1>
                    <p className="fpvctrl__header--description">
                      Most advanced FPV goggles for immersive drone flying
                      experiences.
                    </p>
                    <Link
                      to="/fpvonepilot"
                      className="btn btn--primary btn--med"
                      onMouseDown={event => event.preventDefault()}
                    >
                      Discover
                    </Link>
                  </div>
                </div>
                <LazyImage
                  src={require('main/assets/images/homepage/orqa-mkII-pilot.webp')}
                  alt="fpvctrl"
                  className="home__hero--image"
                />
              </div>
            </section>

            <section className="home__hero">
              <div className="home__hero--wrapper">
                <div className="home__hero--content">
                  <div className="home__hero__content--wrapper">
                    <h2 className="home__hero--subtitle">FPV.One</h2>
                    <h1 className="home__hero--title">Race</h1>
                    <p className="fpvctrl__header--description">
                      An advanced headset optimized for the best racing
                      experience.
                    </p>
                    <Link
                      to="/fpvonerace"
                      className="btn btn--primary btn--med"
                      onMouseDown={event => event.preventDefault()}
                    >
                      Discover
                    </Link>
                  </div>
                </div>
                <LazyImage
                  src={require('main/assets/images/homepage/orqa-mkII-race.webp')}
                  alt="fpvctrl"
                  className="home__hero--image"
                />
              </div>
            </section>

            <section className="home__hero">
              <div className="home__hero--wrapper">
                <div className="home__hero--content">
                  <div className="home__hero__content--wrapper">
                    <h2 className="home__hero--subtitle">FPV</h2>
                    <h1 className="home__hero--title">CTRL</h1>
                    <p className="fpvctrl__header--description">
                      Wireless radio controller for FPV drones and simulators.
                    </p>
                    <Link
                      to="/fpvctrl"
                      className="btn btn--primary btn--med"
                      onMouseDown={event => event.preventDefault()}
                    >
                      Discover
                    </Link>
                  </div>
                </div>
                <LazyImage
                  src={require('main/assets/images/homepage/orqa-fpv-ctrl.webp')}
                  alt="fpvctrl"
                  className="home__hero--image"
                />
              </div>
            </section>
          </Slider>
        </section>

        <section className="bigbox">
          <div className="wrapper wrapper--wide ">
            <div className="bigbox__item">
              <div className="bigbox__item__visual">
                <LazyImage
                  src={require('main/assets/images/homepage/homepage-visual-fpvconnect.webp')}
                  alt=""
                />
              </div>
              <div className="bigbox__item__content">
                <p className="t-zeta t-upperext o-40 s-bottom--xlrg">
                  Stay connected. Always.
                </p>
                <h1 className="t-gamma s-bottom--med">FPV.connect</h1>
                <p className="t-delta o-80 s-bottom--med">
                  Experience an unprecedented degree of connectivity on your FPV
                  headset. Use your mobile phone to access HD DVR footage on
                  your FPV.One, right there in the field.
                </p>
                <p className="t-delta o-80 s-bottom--xlrg">
                  Download and install the latest firmware updates right from
                  the Orqa FPV App.
                </p>
                <Link
                  to="/fpvconnect"
                  className="btn btn--primary btn--med"
                  onMouseDown={event => event.preventDefault()}
                >
                  Connect
                </Link>
              </div>
            </div>
            <div className="bigbox__item bigbox__item--invert">
              <div className="bigbox__item__visual">
                <LazyImage
                  src={require('main/assets/images/homepage/homepage-visual-forum.webp')}
                  alt=""
                />
              </div>
              <div className="bigbox__item__content">
                <p className="t-zeta t-upperext o-40 s-bottom--sml">
                  Community
                </p>
                <h1 className="t-gamma s-bottom--med">ORQA Forum</h1>
                <p className="t-delta o-80 s-bottom--xlrg">
                  Enter the Orqa Forum and join the community of FPV pilots. Get
                  insider info, answers to the common issues and much more.
                </p>
                <a
                  href="https://orqafpv.freshdesk.com/support/discussions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn--primary btn--med"
                  onMouseDown={event => event.preventDefault()}
                >
                  Join
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="featured">

          <div className="wrapper">
            <div className="featured__list">
              <div className="featured__item featured__item--headsets">
                <div className="featured__item__content">
                  <h1 className="t-gamma">Headsets</h1>
                  <p className="t-epsilon">
                    Orqa FPV.One is that premium headset we have all been
                    dreaming of, designed for drone racing, drone freestyle, and
                    FPV simulations.
                  </p>
                  <Link to="/fpvone" className="btn btn--primary btn--med">
                    Discover
                  </Link>
                </div>
              </div>
              <div className="featured__item featured__item--antennas">
                <div className="featured__item__content">
                  <h1 className="t-gamma">Antennas</h1>
                  <p className="t-epsilon">
                    High-performance omnidirectional antennas designed and
                    manufactured for the best FPV experience.
                  </p>
                  <button className="btn btn--primary btn--med">
                    Discover
                  </button>
                </div>
              </div>
              <div className="featured__item featured__item--accessories">
                <div className="featured__item__content">
                  <h1 className="t-gamma">Accessories</h1>
                  <p className="t-epsilon">
                    Cases, cables, straps, foam packs... everything you need,
                    built by Orqa.
                  </p>
                  <button className="btn btn--primary btn--med">
                    Discover
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="latestnews">
          <header className="latestnews__heading">
            <div className="wrapper">
              <h2 className="t-zeta t-upperext o-40 s-bottom--xlrg">
                Latest news
              </h2>
            </div>
          </header>
          <div className="wrapper">
            {this.state.newsLoading && <LoadingSpinner />}
            {this.state.latestNews.length !== 0 && (
              <ul className="latestnews__list">
                {this.state.latestNews.map((post: BlogItem, index: number) => (
                  <li key={index} className="latestnews__item">
                    <article>
                      <div className="latestnews__item__top">
                        <header className="latestnews__item__heading">
                          <p className="t-tiny t-upper o-60 s-bottom--sml">
                            {post.publishedDate}
                          </p>
                          <h2 className="t-delta">
                            <Link
                              to={`/news-details/${post.slug}`}
                              onMouseDown={event => event.preventDefault()}
                            >
                              {post.title}
                            </Link>
                          </h2>
                        </header>
                        <div className="latestnews__item__content">
                          <RichText render={post.shortContent} />
                        </div>
                      </div>
                      <div className="latestnews__item__readmore">
                        <Link
                          to={`/news-details/${post.slug}`}
                          className="btn btn--ghost--primary btn--nopadding btn--sml"
                          onMouseDown={event => event.preventDefault()}
                        >
                          Read more
                        </Link>
                      </div>
                    </article>
                  </li>
                ))}
              </ul>
            )}
            {this.state.latestNews.length === 0 && <p>No latest news...</p>}
            {this.state.newsError && <p>{this.state.newsError}</p>}
          </div>
        </section>

        <section className="newsletter" id="newsletter">
          <div className="wrapper">
            <header className="newsletter__heading">
              <p className="t-zeta t-upperext o-40 s-bottom--xlrg">
                Become an insider
              </p>
              <h1 className="t-gamma s-bottom--xlrg">
                Sign up for info, discounts and other ORQA extras.
              </h1>
            </header>
            <div className="newsletter__form">
              <a
                type="submit"
                href="https://dashboard.mailerlite.com/forms/110729/92295446123251216/share"
                id="embedded-subscribe"
                className="btn btn--primary btn--med"
                target="_blank"
                rel="noopener noreferrer"
                onMouseDown={event => event.preventDefault()}
              >
                Subscribe
              </a>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default withRouter(Homepage);
