import React from 'react';
import { motion } from 'framer-motion';
import InView from 'react-intersection-observer';

export const CTA: React.FC = () => (
  <InView triggerOnce>
    {({ ref, inView }) => (
      <section className="fpvpilot__spotlight--primary">
        <motion.h2
          ref={ref}
          initial={{ opacity: 0, y: '30px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '30px',
          }}
          transition={{
            ease: 'easeOut',
            duration: 0.4,
          }}
          className="fpvpilot__spotlight__primary--title t-upper"
        >
          Ready for the next stage?
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: '40px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '40px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.2,
            duration: 0.4,
          }}
          className="fpvpilot__spotlight__primary--description"
        >
          Grab your FPV.One Pilots and elevate your flying experience to a whole
          new level.
        </motion.p>
        <motion.p
          initial={{ opacity: 0, y: '40px' }}
          animate={{
            opacity: inView ? 1 : 0,
            y: inView ? 0 : '40px',
          }}
          transition={{
            ease: 'easeOut',
            delay: 0.4,
            duration: 0.4,
          }}
          className="fpvpilot__spotlight__primary--button"
        >
          <a
            href="https://shop.orqafpv.com/products/orqa-orqa-fpv-one-pilot"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn--primary btn--med"
            onMouseDown={event => event.preventDefault()}
          >
            Buy now
          </a>
        </motion.p>
        <motion.img
          src={require('main/assets/images/fpvonepilot/orqa-cta-stage.webp')}
          alt="FPV PILOT CTA"
          className="fpvpilot__spotlight--image"
        />
      </section>
    )}
  </InView>
);
