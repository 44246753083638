import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export const DefenseDesktop = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [showVector, setShowVector] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<NodeJS.Timeout | null>(null);

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate position adjustments based on 72px increments
  const getAdjustedPosition = (baseValue: string, index: number) => {
    if (windowWidth >= 4072) return baseValue;

    const baseNum = parseFloat(baseValue.replace('%', ''));
    const widthDifference = 4072 - windowWidth;
    const steps = Math.floor(widthDifference / 30);

    // Only adjust first and third indicators (index 0 and 2)
    if (index === 0 || index === 2) {
      const adjustment = steps * 0.3;
      const newValue = baseNum - adjustment;
      return `${newValue}%`;
    }

    return baseValue;
  };

  const getIndicators = () => {
    if (windowWidth < 1921 && windowWidth > 1680) {
      return [
        {
          position: { right: '18%', top: '32%' },
          vector: {
            svg: {
              width: '600',
              height: '70',
              viewBox: '0 0 600 70',
              path: 'M603.5 71L483 1.5H0',
            },
            position: { top: '23%', right: '24.5%' },
          },
          info: {
            top: '30%',
            left: getAdjustedPosition('66.6%', 0),
          },
          text: {
            title: 'MRM2 - 10F',
            description:
              'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV, designed and built for versatility, effectiveness, and cost efficiency with unparalleled EW resilience. ',
          },
        },
        {
          position: { left: '25%', top: '35%' },
          vector: {
            svg: {
              width: '617',
              height: '68',
              viewBox: '0 0 617 68',
              path: 'M1 128.5L79 1H562',
            },
            position: { top: '25.5%', left: '21.4%' },
          },
          info: {
            top: '32%',
            left: '26.8%',
          },
          text: {
            title: 'FPV.Pro',
            description:
              'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and ease of use in the field. With large and tactile glove-operable switches, robust and clean design, and simplified operation, the FPV.Pro has been designed for high-stakes missions.',
          },
        },
        {
          position: { right: '42%', bottom: '22%' },
          vector: {
            svg: {
              width: '580',
              height: '288',
              viewBox: '0 0 580 288',
              path: 'M1 289L44 1L581 1',
            },
            position: { bottom: '33%', right: '18.7%' },
          },
          info: {
            top: '45%',
            left: getAdjustedPosition('77%', 2),
          },
          text: {
            title: 'GCS-1',
            description:
              'Orqa GCS-1 Ground Control System is a complete solution for tactical FPV UAS operations. It contains everything that is required for safe and reliable FPV mission deployments.',
          },
        },
        {
          position: { left: '27%', bottom: '12%' },
          vector: {
            svg: {
              width: '683',
              height: '488',
              viewBox: '0 0 683 488',
              path: 'M1 489L138 1H684',
            },
            position: { bottom: '23%', left: '25%' },
          },
          info: {
            top: '35%',
            left: '35%',
          },
          text: {
            title: 'Tac.Ctrl',
            description:
              'Tac.Ctrl is a tactical remote controller for First Person View (FPV) UAVs, designed for operator safety and robustly built for reliable and precise operation in adverse field environments.',
          },
        },
      ];
    } else if (windowWidth < 1681 && windowWidth > 1440) {
      return [
        {
          position: { right: '18%', top: '32%' },
          vector: {
            svg: {
              width: '600',
              height: '70',
              viewBox: '0 0 600 70',
              path: 'M603.5 71L483 1.5H0',
            },
            position: { top: '22%', right: '25%' },
          },
          info: {
            top: '29%',
            left: getAdjustedPosition('64.5%', 0),
          },
          text: {
            title: 'MRM2 - 10F',
            description:
              'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV, designed and built for versatility, effectiveness, and cost efficiency with unparalleled EW resilience. ',
          },
        },
        {
          position: { left: '25%', top: '35%' },
          vector: {
            svg: {
              width: '617',
              height: '68',
              viewBox: '0 0 617 68',
              path: 'M1 128.5L79 1H562',
            },
            position: { top: '25.5%', left: '21%' },
          },
          info: {
            top: '32%',
            left: '27%',
          },
          text: {
            title: 'FPV.Pro',
            description:
              'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and ease of use in the field. With large and tactile glove-operable switches, robust and clean design, and simplified operation, the FPV.Pro has been designed for high-stakes missions.',
          },
        },
        {
          position: { right: '42%', bottom: '22%' },
          vector: {
            svg: {
              width: '580',
              height: '288',
              viewBox: '0 0 580 288',
              path: 'M1 289L44 1L581 1',
            },
            position: { bottom: '34%', right: '15.5%' },
          },
          info: {
            top: '43%',
            left: getAdjustedPosition('80%', 2),
          },
          text: {
            title: 'GCS-1',
            description:
              'Orqa GCS-1 Ground Control System is a complete solution for tactical FPV UAS operations. It contains everything that is required for safe and reliable FPV mission deployments.',
          },
        },
        {
          position: { left: '27%', bottom: '12%' },
          vector: {
            svg: {
              width: '683',
              height: '488',
              viewBox: '0 0 683 488',
              path: 'M1 489L138 1H684',
            },
            position: { bottom: '24%', left: '25%' },
          },
          info: {
            top: '32%',
            left: '36%',
          },
          text: {
            title: 'Tac.Ctrl',
            description:
              'Tac.Ctrl is a tactical remote controller for First Person View (FPV) UAVs, designed for operator safety and robustly built for reliable and precise operation in adverse field environments.',
          },
        },
      ];
    } else if (windowWidth < 1441 && windowWidth > 1280) {
      return [
        {
          position: { right: '18%', top: '32%' },
          vector: {
            svg: {
              width: '600',
              height: '70',
              viewBox: '0 0 600 70',
              path: 'M603.5 71L483 1.5H0',
            },
            position: { top: '17.5%', right: '25.3%' },
          },
          info: {
            top: '26%',
            left: getAdjustedPosition('61%', 0),
          },
          text: {
            title: 'MRM2 - 10F',
            description:
              'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV, designed and built for versatility, effectiveness, and cost efficiency with unparalleled EW resilience. ',
          },
        },
        {
          position: { left: '25%', top: '35%' },
          vector: {
            svg: {
              width: '617',
              height: '68',
              viewBox: '0 0 617 68',
              path: 'M1 128.5L79 1H562',
            },
            position: { top: '20.5%', left: '18.5%' },
          },
          info: {
            top: '29.5%',
            left: '25.6%',
          },
          text: {
            title: 'FPV.Pro',
            description:
              'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and ease of use in the field. With large and tactile glove-operable switches, robust and clean design, and simplified operation, the FPV.Pro has been designed for high-stakes missions.',
          },
        },
        {
          position: { right: '42%', bottom: '22%' },
          vector: {
            svg: {
              width: '580',
              height: '288',
              viewBox: '0 0 580 288',
              path: 'M1 289L44 1L581 1',
            },
            position: { bottom: '35%', right: '9.3%' },
          },
          info: {
            top: '37.5%',
            left: getAdjustedPosition('84%', 2),
          },
          text: {
            title: 'GCS-1',
            description:
              'Orqa GCS-1 Ground Control System is a complete solution for tactical FPV UAS operations. It contains everything that is required for safe and reliable FPV mission deployments.',
          },
        },
        {
          position: { left: '27%', bottom: '12%' },
          vector: {
            svg: {
              width: '600',
              height: '450',
              viewBox: '0 0 600 450',
              path: 'M1 489L138 1H684',
            },
            position: { bottom: '24%', left: '22.5%' },
          },
          info: {
            top: '28.3%',
            left: '34%',
          },
          text: {
            title: 'Tac.Ctrl',
            description:
              'Tac.Ctrl is a tactical remote controller for First Person View (FPV) UAVs, designed for operator safety and robustly built for reliable and precise operation in adverse field environments.',
          },
        },
      ];
    } else {
      return [
        {
          position: { right: '18%', top: '32%' },
          vector: {
            svg: {
              width: '600',
              height: '70',
              viewBox: '0 0 600 70',
              path: 'M603.5 71L483 1.5H0',
            },
            position: { top: '23%', right: '24.5%' },
          },
          info: {
            top: '30%',
            left: getAdjustedPosition('66.6%', 0),
          },
          text: {
            title: 'MRM2 - 10F',
            description:
              'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV, designed and built for versatility, effectiveness, and cost efficiency with unparalleled EW resilience. ',
          },
        },
        {
          position: { left: '25%', top: '35%' },
          vector: {
            svg: {
              width: '617',
              height: '68',
              viewBox: '0 0 617 68',
              path: 'M1 128.5L79 1H562',
            },
            position: { top: '25.5%', left: '21.4%' },
          },
          info: {
            top: '32%',
            left: '26.8%',
          },
          text: {
            title: 'FPV.Pro',
            description:
              'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and ease of use in the field. With large and tactile glove-operable switches, robust and clean design, and simplified operation, the FPV.Pro has been designed for high-stakes missions.',
          },
        },
        {
          position: { right: '42%', bottom: '22%' },
          vector: {
            svg: {
              width: '580',
              height: '288',
              viewBox: '0 0 580 288',
              path: 'M1 289L44 1L581 1',
            },
            position: { bottom: '33%', right: '18.7%' },
          },
          info: {
            top: '45%',
            left: getAdjustedPosition('77%', 2),
          },
          text: {
            title: 'GCS-1',
            description:
              'Orqa GCS-1 Ground Control System is a complete solution for tactical FPV UAS operations. It contains everything that is required for safe and reliable FPV mission deployments.',
          },
        },
        {
          position: { left: '27%', bottom: '12%' },
          vector: {
            svg: {
              width: '683',
              height: '488',
              viewBox: '0 0 683 488',
              path: 'M1 489L138 1H684',
            },
            position: { bottom: '23%', left: '25%' },
          },
          info: {
            top: '35%',
            left: '35%',
          },
          text: {
            title: 'Tac.Ctrl',
            description:
              'Tac.Ctrl is a tactical remote controller for First Person View (FPV) UAVs, designed for operator safety and robustly built for reliable and precise operation in adverse field environments.',
          },
        },
      ];
    }
  };

  const indicators = getIndicators();

  const openIndicator = (index: number) => {
    setActiveIndex(index);
    setShowVector(false); // Reset vector visibility
    setShowText(false); // Reset text visibility

    // Start circle animation (faster - 300ms instead of 500ms)
    setTimeout(() => {
      setShowVector(true); // Only show vector after circle completes

      // Start text animation after vector completes (300ms)
      setTimeout(() => {
        setShowText(true);
      }, 300);
    }, 300);
  };

  const closeIndicator = () => {
    setIsClosing(true);
    setShowText(false);

    setTimeout(() => {
      setShowVector(false);
      setIsClosing(false);
      setActiveIndex(null);
    }, 300);
  };

  const handleHoverStart = (index: number) => {
    if (activeIndex !== null && activeIndex !== index) {
      // If another indicator is open, close it first
      closeIndicator();
      setTimeout(() => openIndicator(index), 500);
    } else {
      openIndicator(index);
    }
  };

  const handleHoverEnd = () => {
    // Set a delay before closing to allow for mouse movement between elements
    const timeout = setTimeout(() => {
      if (activeIndex !== null) {
        closeIndicator();
      }
    }, 300); // 300ms delay before closing

    setHoverTimeout(timeout);
  };

  // Clean up timeouts on unmount
  useEffect(() => {
    return () => {
      if (hoverTimeout) clearTimeout(hoverTimeout);
    };
  }, [hoverTimeout]);

  return (
    <div className="tv-container">
      <h1 className="main-title">Rugged tactical defense solution</h1>

      <div className="defense-cta-section">
        <a
          href="https://orqafpv.com/asda/contact"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--secondary btn--sml defense-cta-button"
          onMouseDown={event => event.preventDefault()}
        >
          get in touch
        </a>
      </div>

      {windowWidth > 1440 ? (
        <>
          {indicators?.map((indicator, index) => (
            <div
              key={index}
              className="circle-indicator"
              style={{
                position: 'absolute',
                width: '100px',
                height: '100px',
                ...indicator.position,
                cursor: 'pointer',
              }}
              onMouseEnter={() => handleHoverStart(index)}
              onMouseLeave={handleHoverEnd}
            >
              {activeIndex === index ? (
                <motion.svg
                  initial={{ scale: 0 }}
                  animate={isClosing ? { scale: 0 } : { scale: 1 }}
                  transition={{ duration: 0.5 }}
                  viewBox="0 0 100 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    fill="url(#paint0_radial_127_1107)"
                  />
                  <circle cx="50" cy="50" r="17.045" fill="#EE2737" />{' '}
                  {/* 27.2727 * (100/160) ≈ 17.045 */}
                  <defs>
                    <radialGradient
                      id="paint0_radial_127_1107"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(50 50) rotate(90) scale(50)"
                    >
                      <stop stopColor="#F1274C" stopOpacity="0.3" />
                      <stop offset="1" stopColor="#F1274C" stopOpacity="0" />
                    </radialGradient>
                  </defs>
                </motion.svg>
              ) : (
                <svg
                  viewBox="0 0 100 100"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="50"
                    cy="50"
                    r="50"
                    fill="url(#paint0_radial_123_925)"
                  />
                  <circle cx="50" cy="50" r="17.045" fill="white" />
                  <defs>
                    <radialGradient
                      id="paint0_radial_123_925"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(50 50) rotate(90) scale(50)"
                    >
                      <stop stopColor="white" stopOpacity="0.2" />
                      <stop offset="1" stopColor="white" stopOpacity="0.2" />
                    </radialGradient>
                  </defs>
                </svg>
              )}
            </div>
          ))}
        </>
      ) : (
        <>
          {indicators?.map((indicator, index) => (
            <div
              key={index}
              className="circle-indicator"
              style={{
                position: 'absolute',
                width: '58px',
                height: '58px',
                ...indicator.position,
                cursor: 'pointer',
              }}
              onMouseEnter={() => handleHoverStart(index)}
              onMouseLeave={handleHoverEnd}
            >
              {activeIndex === index ? (
                <motion.svg
                  initial={{ scale: 0 }}
                  animate={isClosing ? { scale: 0 } : { scale: 1 }}
                  transition={{ duration: 0.5 }}
                  viewBox="0 0 58 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="29"
                    cy="29"
                    r="29"
                    fill="url(#paint0_radial_127_1107)"
                  />
                  <circle cx="29" cy="29" r="9.886" fill="#EE2737" />
                  <defs>
                    <radialGradient
                      id="paint0_radial_127_1107"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(29 29) rotate(90) scale(29)"
                    >
                      <stop stopColor="#F1274C" stopOpacity="0.3" />
                      <stop offset="1" stopColor="#F1274C" stopOpacity="0" />
                    </radialGradient>
                  </defs>
                </motion.svg>
              ) : (
                <svg
                  viewBox="0 0 58 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="29"
                    cy="29"
                    r="29"
                    fill="url(#paint0_radial_123_925)"
                  />
                  <circle cx="29" cy="29" r="9.886" fill="white" />
                  <defs>
                    <radialGradient
                      id="paint0_radial_123_925"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(29 29) rotate(90) scale(29)"
                    >
                      <stop stopColor="white" stopOpacity="0.2" />
                      <stop offset="1" stopColor="white" stopOpacity="0.2" />
                    </radialGradient>
                  </defs>
                </svg>
              )}
            </div>
          ))}
        </>
      )}

      <AnimatePresence>
        {showVector && activeIndex !== null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              ...indicators?.[activeIndex].vector.position,
              transform: 'translate(80px, 80px)',
            }}
          >
            <svg
              width={indicators?.[activeIndex].vector.svg.width}
              height={indicators?.[activeIndex].vector.svg.height}
              viewBox={indicators?.[activeIndex].vector.svg.viewBox}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                d={indicators?.[activeIndex].vector.svg.path}
                stroke="#EE2737"
                strokeWidth="5.06546"
                initial={{ pathLength: 0 }}
                animate={isClosing ? { pathLength: 0 } : { pathLength: 1 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showText && activeIndex !== null && !isClosing && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="info-container"
            style={{
              position: 'absolute',
              top: indicators?.[activeIndex].info.top,
              left: indicators?.[activeIndex].info.left,
            }}
          >
            <motion.div
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.2 }}
              className="info-title"
            >
              {indicators?.[activeIndex].text.title}
            </motion.div>
            <motion.div
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.2 }}
              className="info-description"
            >
              {indicators?.[activeIndex].text.description}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
