import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import logo from '../../../main/assets/images/defense/Logo-black.png';

export const DefenseTV = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [showVector, setShowVector] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [circleAnimationComplete, setCircleAnimationComplete] = useState(false);
  const [vectorAnimationComplete, setVectorAnimationComplete] = useState(false);

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAdjustedPosition = (baseValue: string, index: number) => {
    if (windowWidth >= 4072) return baseValue;

    const baseNum = parseFloat(baseValue.replace('%', ''));
    const widthDifference = 4072 - windowWidth;
    const steps = Math.floor(widthDifference / 72);

    if (index === 0 || index === 2) {
      const adjustment = steps * 0.5;
      const newValue = baseNum - adjustment;
      return `${newValue}%`;
    }

    return baseValue;
  };

  // Configuration for each indicator including their vector paths
  const indicators = [
    {
      position: { right: '18%', top: '32%' },
      vector: {
        svg: {
          width: '1700',
          height: '150',
          viewBox: '0 0 1700 150',
          path: 'M1770.94 153L1383.44 3.5H0.566895',
        },
        position: { top: '26.7%', right: '21.8%' },
      },
      info: {
        top: '32.5%',
        left: getAdjustedPosition('37.2%', 0),
      },
      text: {
        title: 'MRM2 - 10F',
        description:
          'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV, designed and built for versatility, effectiveness, and cost efficiency with unparalleled EW resilience. ',
      },
    },
    {
      position: { left: '25%', top: '35%' },
      vector: {
        svg: {
          width: '1908',
          height: '408',
          viewBox: '0 0 1908 408',
          path: 'M2 203L281 3.41211L1663.87 3.41211',
        },
        position: { top: '25.4%', left: '24.7%' },
      },
      info: {
        top: '32%',
        left: '35%',
      },
      text: {
        title: 'FPV.Pro',
        description:
          'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and ease of use in the field. With large and tactile glove-operable switches, robust and clean design, and simplified operation, the FPV.Pro has been designed for high-stakes missions.',
      },
    },
    {
      position: { right: '42%', bottom: '22%' },
      vector: {
        svg: {
          width: '1908',
          height: '500',
          viewBox: '0 0 1908 500',
          path: 'M1906 497.5 L1383.3 3 H0.433105',
        },
        position: { bottom: '28.8%', right: '45.4%' },
      },
      info: {
        top: '53.8%',
        left: getAdjustedPosition('9.2%', 2),
      },
      text: {
        title: 'GCS-1',
        description:
          'Orqa GCS-1 Ground Control System is a complete solution for tactical FPV UAS operations. It contains everything that is required for safe and reliable FPV mission deployments.',
      },
    },
    {
      position: { left: '27%', bottom: '12%' },
      vector: {
        svg: {
          width: '1666',
          height: '1037',
          viewBox: '0 0 1666 1037',
          path: 'M3 1040 L286.63 3 H1669.5',
        },
        position: { bottom: '17.9%', left: '26.5%' },
      },
      info: {
        top: '39.7%',
        left: '37.6%',
      },
      text: {
        title: 'Tac.Ctrl',
        description:
          'Tac.Ctrl is a tactical remote controller for First Person View (FPV) UAVs, designed for operator safety and robustly built for reliable and precise operation in adverse field environments.',
      },
    },
  ];

  // Animation sequence control
  useEffect(() => {
    let timer1: NodeJS.Timeout, timer2: NodeJS.Timeout, timer3: NodeJS.Timeout;

    const startAnimationSequence = (index: number) => {
      // Reset states
      setShowText(false);
      setIsClosing(false);
      setCircleAnimationComplete(false);
      setVectorAnimationComplete(false);

      // Open the indicator
      setActiveIndex(index);

      // Start circle animation
      const circleTimer = setTimeout(() => {
        setCircleAnimationComplete(true);
        setShowVector(true);

        // Show text after vector animation completes
        timer1 = setTimeout(() => {
          setShowText(true);

          timer2 = setTimeout(() => {
            setIsClosing(true);
            setShowText(false);

            timer3 = setTimeout(() => {
              setShowVector(false);
              const nextIndex = (index + 1) % indicators.length;
              startAnimationSequence(nextIndex);
            }, 500);
          }, 15000);
        }, 500);
      }, 500);

      return () => clearTimeout(circleTimer);
    };

    // Start first animation after 3 seconds
    timer1 = setTimeout(() => startAnimationSequence(0), 15000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, [indicators.length]);

  return (
    <div className="tv-container">
      <img
        src={logo}
        style={{
          position: 'absolute',
          right: 200,
          top: 120,
          height: '200px',
          width: 'auto',
        }}
      />
      {/* Top left title */}
      <h1 className="main-title">Rugged tactical defense solution</h1>
      {/* Bottom right container */}
      <div className="content-container">
        <h2 className="content-title">Learn More</h2>
        <div className="content-subcontainer">
          <div className="qr-image-placeholder" />
          <p className="website-link">www.orqafpv.com/asda</p>
        </div>
      </div>
      {/* Circle indicators */}
      {indicators.map((indicator, index) => (
        <div
          key={index}
          className="circle-indicator"
          style={{
            position: 'absolute',
            width: '160px',
            height: '160px',
            ...indicator.position,
          }}
        >
          {activeIndex === index ? (
            <motion.svg
              initial={{ scale: 0 }}
              animate={
                isClosing
                  ? { scale: 0, transition: { delay: 0.5 } } // Wait for vector to close first
                  : { scale: 1 }
              }
              transition={{ duration: 0.5 }}
              viewBox="0 0 160 160"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="80"
                cy="80"
                r="80"
                fill="url(#paint0_radial_127_1107)"
              />
              <circle cx="79.999" cy="79.9993" r="27.2727" fill="#EE2737" />
              <defs>
                <radialGradient
                  id="paint0_radial_127_1107"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(80 80) rotate(90) scale(80)"
                >
                  <stop stopColor="#F1274C" stopOpacity="0.3" />
                  <stop offset="1" stopColor="#F1274C" stopOpacity="0" />
                </radialGradient>
              </defs>
            </motion.svg>
          ) : (
            <svg
              viewBox="0 0 160 160"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="80"
                cy="80"
                r="80"
                fill="url(#paint0_radial_123_925)"
              />
              <circle cx="80" cy="80" r="27.2727" fill="white" />
              <defs>
                <radialGradient
                  id="paint0_radial_123_925"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(80 80) rotate(90) scale(80)"
                >
                  <stop stopColor="white" stopOpacity="0.2" />
                  <stop offset="1" stopColor="white" stopOpacity="0.2" />
                </radialGradient>
              </defs>
            </svg>
          )}
        </div>
      ))}
      {/* Vector animation - unique for each indicator */}
      <AnimatePresence>
        {showVector && activeIndex !== null && circleAnimationComplete && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              ...indicators[activeIndex].vector.position,
              transform: 'translate(80px, 80px)',
            }}
            key={`vector-${activeIndex}`}
          >
            <svg
              width={indicators[activeIndex].vector.svg.width}
              height={indicators[activeIndex].vector.svg.height}
              viewBox={indicators[activeIndex].vector.svg.viewBox}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                d={indicators[activeIndex].vector.svg.path}
                stroke="#EE2737"
                strokeWidth="5.06546"
                initial={{ pathLength: 0 }}
                animate={isClosing ? { pathLength: 0 } : { pathLength: 1 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
                onAnimationComplete={() => setVectorAnimationComplete(true)}
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Text container - shown for all indicators */}
      <AnimatePresence>
        {showText &&
          activeIndex !== null &&
          !isClosing &&
          vectorAnimationComplete && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="info-container"
              style={{
                position: 'absolute',
                top: indicators[activeIndex].info.top,
                left: indicators[activeIndex].info.left,
              }}
            >
              <motion.div
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.2 }}
                className="info-title"
              >
                {indicators[activeIndex].text.title}
              </motion.div>
              <motion.div
                initial={{ y: -20 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.2, delay: 0.2 }}
                className="info-description"
              >
                {indicators[activeIndex].text.description}
              </motion.div>
            </motion.div>
          )}
      </AnimatePresence>
    </div>
  );
};
