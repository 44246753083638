import React, { useEffect, useState } from 'react';

import {
  DefenseDesktop,
  DefenseMobile,
  DefenseTablet,
  DefenseTV,
} from 'modules/defense';

export const Defense = () => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  console.log(windowWidth);
  return (
    <>
      {windowWidth > 2159 && <DefenseTV />}
      {windowWidth <= 2159 && windowWidth > 1280 && <DefenseDesktop />}
      {windowWidth <= 1280 && windowWidth > 600 && <DefenseTablet />}
      {windowWidth <= 600 && <DefenseMobile />}
    </>
  );
};
