import * as React from 'react';

import { SEO } from 'main/components';
import { LazyImage } from 'main/components/LazyImage';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class FPVConnect extends React.Component<{}, State> {
  state: State = {
    loading: true,
    isMobile: false,
  };

  render() {
    return (
      <section className="fpvconnect">
        <SEO
          seoTitle="ORQA - FPV.CONNECT"
          seoDescription="DVR footage directly in the field. Stream your flight to your phone in real-time. Use your mobile phone to access that sweet HD DVR footage on your FPV.One, right there in the field."
        />
        <section className="fpvconnect__splash">
          <div className="fpvconnect__splash__content">
            <div className="wrapper wrapper--narrow">
              <h1 className="t-gamma t-primary s-bottom--med">FPV.Connect</h1>
              <p className="t-zeta o-80">
                Experience an unprecedented degree of connectivity
              </p>
            </div>
          </div>
          <div className="fpvconnect__splash__visual">
            <LazyImage
              src={require('main/assets/pages/fpvconnect/fpvconnect-cover.webp')}
              alt=""
            />
          </div>
        </section>
        <div className="wrapper wrapper--wide" id="DVR">
          <section className="splitsection">
            <header className="splitsection__header">
              <h2 className="t-beta s-bottom--xlrg">
                DVR footage directly in the field.
              </h2>
              <p className="t-delta o-80">
                Stream your flight to your phone in real-time. Use your mobile
                phone to access that sweet HD DVR footage on your FPV.One, right
                there in the field.
              </p>
            </header>
            <div className="splitsection__content">
              <LazyImage
                src={require('main/assets/pages/fpvconnect/fpvconnect-app.webp')}
                alt=""
                className="splitsection__visual"
              />
            </div>
          </section>
        </div>
        <div className="wrapper wrapper--wide" id="InstallUpdates">
          <section className="splitsection splitsection--inverted">
            <header className="splitsection__header">
              <h2 className="t-beta s-bottom--xlrg">
                Install firmware updates
              </h2>
              <p className="t-delta o-80">
                Download and install the latest firmware updates right from the
                Orqa FPV App.
              </p>
              <div className="appstore">
                <a
                  href="https://bit.ly/FPVConnectAPP"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  <LazyImage
                    src={require('main/assets/pages/fpvconnect/fpvconnect-googleplay.webp')}
                    alt="Download on the App Store"
                  />
                </a>
                <a
                  href="https://bit.ly/FPVConnect"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  <LazyImage
                    src={require('main/assets/pages/fpvconnect/fpvconnect-appstore.webp')}
                    alt="Get it on Google Play"
                  />
                </a>
              </div>
            </header>
            <div className="splitsection__content">
              <LazyImage
                src={require('main/assets/pages/fpvconnect/fpvconnect-firmware.webp')}
                alt=""
                className="splitsection__visual"
              />
            </div>
          </section>
        </div>
        <div className="wrapper wrapper--wide" id="FPVConnectInAction">
          <section className="impactblock">
            <header className="impactblock__header">
              <h2 className="t-beta s-bottom--xlrg">
                Live stream directly from your mobile device.
              </h2>
              <p className="t-delta o-80">
                Live stream your footages to social media directly from your
                mobile device. Share your experience with other pilots and
                connect to the community.
              </p>
            </header>
            <div className="impactblock__content">
              <LazyImage
                src={require('main/assets/pages/fpvconnect/fpvconnect-streaming.webp')}
                alt=""
                className="impactblock__visual"
              />
            </div>
          </section>
        </div>
        <section className="featuresection">
          <div className="wrapper wrapper--wide">
            <div>
              <ul className="featuresection__blocklist featuresection__blocklist--three">
                <li>
                  <p className="t-epsilon o-80">
                    Stream directly to your device
                  </p>
                </li>
                <li>
                  <p className="t-epsilon o-80">Multicast streaming</p>
                </li>
                <li>
                  <div>
                    <p className="t-epsilon o-80">
                      Live stream on social media
                    </p>
                    <div className="alert alert--warning s-top--sml">
                      Coming soon
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="cta">
          <div className="wrapper wrapper--wide">
            <header className="cta__heading">
              <h1 className="t-beta t-primary">Ready to connect?</h1>
            </header>
            <div className="cta__content">
              <p className="t-delta">
                Broadcast, update, connect with other FPV pilots and share your
                content with a few clicks.
              </p>
            </div>
            <div className="cta__action">
              <a
                href="https://shop.orqafpv.com/products/orqa-orqa-fpv-connect"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--primary btn--med"
                onMouseDown={event => event.preventDefault()}
              >
                Connect now
              </a>
            </div>
          </div>
        </section>
      </section>
    );
  }
}
