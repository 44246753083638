import React from 'react';

export const FullControl = () => {
  return (
    <section className="dreamX__fullcontrol__section">
      <div className="dreamX__fullcontrol-content--wrapper responsive__padding">
        <div>
          <h2 className="dreamX__fullcontrol-title">
            DAILED IN AND READY TO RIP!
          </h2>
        </div>
        <p className="dreamX__body">
          Dream X BNF and HD aren’t just built to perform - they’re fine-tuned
          by the world’s top FPV freestyle pilots to deliver an unrivaled flight
          experience straight out of the box. Every parameter, from PID tuning
          to filter settings, has been optimized to provide smooth, responsive,
          and predictable handling in any environment.
        </p>
      </div>

      <img
        src={require('main/assets/images/dreamX/Full Control wide.webp')}
        className="dreamX__fullcontrol__image-desktop--wide"
      />
      <img
        src={require('main/assets/images/dreamX/Full control.webp')}
        className="dreamX__fullcontrol__image-desktop--narrow"
      />
      <img
        src={require('main/assets/images/dreamX/Full control mobile.webp')}
        className="dreamX__fullcontrol__image-mobile"
      />
    </section>
  );
};
