import { motion } from 'framer-motion';
import React from 'react';
import InView from 'react-intersection-observer';

import { LazyImage } from 'main/components';

export const RaceCTA: React.FC = () => (
  <InView triggerOnce={false}>
    {({ ref, inView }) => (
      <div className="fpvrace--testimonial fpvrace__testimonial--quaternary">
        <div
          ref={ref}
          className="fpvrace__spotlight--wrapper fpvrace__spotlight__wrapper--secondary"
        >
          <div className="fpvrace__spotlight__content--secondary">
            <motion.h2
              ref={ref}
              initial={{ opacity: 0, y: '20px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '20px' }}
              transition={{ ease: 'circOut', duration: 0.3 }}
              className="fpvpilot__spotlight--title"
            >
              Ready for your next race?
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.3, delay: 0.3 }}
              className="fpvpilot__spotlight--description"
            >
              Start your journey with Orqa FPV.One Race today and take your
              experience to a whole new level.
            </motion.p>
            <motion.p
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.3, delay: 0.4 }}
            >
              <a
                href="https://shop.orqafpv.com/products/orqa-orqa-fpv-one-race"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn--primary btn--med"
                onMouseDown={event => event.preventDefault()}
              >
                Buy now
              </a>
            </motion.p>
          </div>
        </div>
        <LazyImage
          src={require('main/assets/images/fpvonerace/ready-for-the-next-race.webp')}
          alt="Race cta spotlight"
          className="fpvpilot__spotlight__mobile__secondary--img"
        />
      </div>
    )}
  </InView>
);
