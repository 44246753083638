import * as React from 'react';

import { SEO } from 'main/components';

interface State {
  loading: boolean;
  isMobile: boolean;
}

export class About extends React.Component<{}, State> {
  render() {
    return (
      <>
        <SEO seoTitle="ORQA - About" />
        <section className="about">
          <section className="splash">
            <div className="splash__content">
              <div className="wrapper wrapper--narrow">
                <h1 className="t-zeta t-upperext o-40 s-bottom--sml">ORQA</h1>
                <p className="t-gamma t-primary s-bottom--med">About Us</p>
              </div>
            </div>
          </section>

          <section className="timeline">
            <div className="wrapper wrapper--narrow">
              <ul className="timeline__list">
                <li className="timeline__item">
                  <div className="timeline__item__content">
                    <h2 className="t-epsilon">
                      Orqa d.o.o. was founded in 2018.
                    </h2>
                    <p className="t-zeta o-60">
                      Orqa was founded by Srdjan, Vlatko and Ivan in late 2018
                      with the mission to innovate products across the FPV
                      drones industry.
                    </p>
                  </div>
                </li>
                <li className="timeline__item">
                  <div className="timeline__item__content">
                    <h2 className="t-epsilon">Orqa at CES 2019</h2>
                    <p className="t-zeta o-60">
                      In January 2019, Orqa presented the prototype of flagship
                      goggles for FPV racing and freestyle at the world’s
                      largest consumer electronics exhibition at CES in Las
                      Vegas.
                    </p>
                  </div>
                </li>
                <li className="timeline__item">
                  <div className="timeline__item__content">
                    <h2 className="t-epsilon">FPV.One Kickstarter campaign</h2>
                    <p className="t-zeta o-60">
                      After two years of prototyping and development, Orqa
                      started its Kickstarter campaign for FPV.One on June 15th
                      and successfully sold more than 600 goggles.
                    </p>
                  </div>
                </li>
                <li className="timeline__item">
                  <div className="timeline__item__content">
                    <h2 className="t-epsilon">FPV.Connect release</h2>
                    <p className="t-zeta o-60">
                      In May 2020, Orqa released its first connectivity add-on
                      enabling access to DVR as well as livestream of flight in
                      real time.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <div className="wrapper wrapper--narrow">
            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">
                  History
                </h2>
              </header>
              <div className="about__block__content">
                <p className="t-zeta s-bottom--sml">
                  Orqa is a company whose mission is to become the World’s
                  number one technology provider for First Person View (FPV) and
                  advanced Remote Reality (RR) applications by developing
                  enabling technology for next-generation vision systems.
                </p>
                <p className="t-zeta s-bottom--sml">
                  It was founded in 2018 by Srdjan Kovacevic, Ivan Jelusic, and
                  Vlatko Matijevic, and is located in Osijek, where its
                  development and production is also based.
                </p>
                <p className="t-zeta">
                  The prototype of its FPV.One video goggles for racing drone
                  pilots was first presented at the CES in Las Vegas in January
                  2019, and on June 15th it launched its Kickstarter campaign
                  through which it managed to reach its goal in just two minutes
                  after the launch, and was 200% over its campaign goal in five
                  minutes after the launch. In January 2020, it started
                  delivering the first batches of the FPV.One goggles to
                  resellers.
                </p>
              </div>
            </section>

            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">Team</h2>
              </header>
              <div className="about__block__content">
                <p className="t-zeta s-bottom--sml">
                  The Orqa team is a group of FPV enthusiasts and engineers on a
                  mission to design and build the World’s best FPV gear.
                </p>
                <p className="t-zeta s-bottom--sml">
                  It consists of more than thirty employees - engineers (mobile
                  and embedded software, electronics, RF, mechanical, optical),
                  physicists, assembly technicians, and sales & marketing
                  specialists; this multidisciplinary team makes for a dynamic
                  and creative working environment.
                </p>
                <p className="t-zeta">
                  Check us on{' '}
                  <a
                    href="https://www.linkedin.com/company/orqafpv/mycompany/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onMouseDown={event => event.preventDefault()}
                  >
                    LinkedIn.
                  </a>
                </p>
              </div>
            </section>

            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">
                  Technology{' '}
                </h2>
              </header>
              <div className="about__block__content">
                <p className="t-zeta s-bottom--sml">
                  Orqa’s technology pipeline is focused on innovating and
                  developing all parts of the FPV technology stack including
                  antennas, radio controllers, headsets and most importantly,
                  low latency video signal transport systems.
                </p>
                <p className="t-zeta">
                  In order to achieve that, they employ a plethora of different
                  technologies - electronic, mechanical, and optical
                  engineering, embedded and backend systems development,
                  prototyping, as well as manufacturing.
                </p>
              </div>
            </section>

            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">
                  Culture{' '}
                </h2>
              </header>
              <div className="about__block__content">
                <p className="t-zeta s-bottom--sml">
                  The company’s innovation-oriented mindset comes from the
                  uninhibitedness of its young, creative team, with the average
                  age of only 26. For the majority of such young people, working
                  at Orqa serves as their very first working experience, giving
                  them a chance to work and learn in a dynamic environment, but
                  also giving us a chance to enrich our team with a brand-new
                  way of thinking.
                </p>
                <p className="t-zeta">
                  Gradually, we build our company based on our roles, ranging
                  from engineers, physicists, and assembly technicians to sales
                  and marketing specialists, but also on our tendency to build a
                  unique, well-rounded product with the combined effort of young
                  professionals with various skills and perspectives utilized
                  towards a common goal.
                </p>
                <p className="t-zeta">
                  Ultimately, even though we aim high, we always keep a humble
                  mindset by valuing the community feedback and wishes, which,
                  in return, helps us to grow and improve.
                </p>
              </div>
            </section>

            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">
                  Community driven{' '}
                </h2>
              </header>
              <div className="about__block__content">
                <p className="t-zeta s-bottom--sml">
                  Here at Orqa, we value the community feedback immensely. Ever
                  since January 2019, the FPV community gave us instrumental
                  advice and feedback on what our finished product should be
                  like, so we decided to take more time to adjust and perfect
                  it.
                </p>
                <p className="t-zeta">
                  Reacting and adjusting to customer needs and developing a
                  two-way relationship with product consumers is one of the
                  highest values that Orqa as a company wants to achieve, be it
                  through our community forum, social network pages, or our
                  customer support which is considered to be one of the best in
                  the business.
                </p>
              </div>
            </section>

            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">
                  Made in EU
                </h2>
              </header>
              <div className="about__block__content">
                <p className="t-zeta s-bottom--sml">
                  Orqa is one of the few companies in the FPV market that does
                  almost all of its engineering in house: mechanical
                  engineering, electronics design, RF design, firmware
                  development, optics design, prototyping, etc. All of our
                  products are labelled as “Made in Europe” since everything,
                  except micro-displays, is made in Osijek.
                </p>
                <p className="t-zeta">
                  The prototyping lab in headquarters provides high-end
                  equipment such as FDM 3D printer Stratsys F170, big SLS 3D
                  Printer Formiga P110 Velocis, advanced laser cutters and CNC
                  machining, as well as other relevant equipment needed for
                  developing hardware devices. In addition, Orqa owns high end
                  RF design and testing tools.
                </p>
              </div>
            </section>

            <section className="about__block">
              <header className="about__block__heading">
                <h2 className="t-zeta t-upperext o-80 s-bottom--sml">
                  Company information
                </h2>
              </header>
              <div className="about__block__content">
                <ul className="t-zeta ">
                  <li className="s-bottom--sml">Orqa d.o.o.</li>
                  <li className="s-bottom--sml">
                    J.J.Strossmayera 341, 31000 Osijek, Croatia
                  </li>
                  <li className="s-bottom--sml">OIB: 98232144692</li>
                  <li className="s-bottom--sml"> VAT ID: HR98232144692</li>
                  <li>
                    <a
                      href="mailto:info@orqafpv.com"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseDown={event => event.preventDefault()}
                    >
                      info@orqafpv.com
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        </section>
      </>
    );
  }
}
