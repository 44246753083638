import React from 'react';

export const ImmersionRCFrequency = () => {
  return (
    <section className="immersionRCFreq__section">
      <div className="immersionRCFreq__content--wrapper">
        <img
          src={require('main/assets/images/dreamX/DreamX box.webp')}
          alt="Pilot spotlight"
          className="immersionRCFreq__image--desktop"
        />
        <img
          src={require('main/assets/images/dreamX/Gray box.webp')}
          alt="Pilot spotlight"
          className="immersionRCFreq__image--mobile"
        />
        <div className="immersionRCFreq__text--wrapper responsive__padding">
          <div className="immersionRCFreq__text--block">
            <div className="title__column--gap">
              <p className="dreamX__subtitle align--left">UP TO</p>
              <div style={{ display: 'flex', gap: '10px' }}>
                <h2 className="dreamX__title t-primary align--left">500</h2>
                <h2 className="dreamX__title align--left">HZ</h2>
              </div>
            </div>
            <p
              className="dreamX__body align--left"
              style={{ paddingTop: '20px' }}
            >
              Precision at the speed of thought. With refresh rates reaching up
              to 500Hz, the ImmersionRC Hybrid Ultimate delivers an
              ultra-responsive link that keeps up with every flick, roll, and
              dive - giving you total control in the heat of the moment.
            </p>
          </div>
          <div className="immersionRCFreq__text--block">
            <div className="title__column--gap">
              <p className="dreamX__subtitle align--left">UP TO</p>
              <div style={{ display: 'flex', gap: '10px' }}>
                <h2 className="dreamX__title t-primary align--left">1500</h2>
                <h2 className="dreamX__title align--left">MW</h2>
              </div>
            </div>
            <p
              className="dreamX__body align--left"
              style={{ paddingTop: '20px' }}
            >
              No interference, no dropouts - just pure, uninterrupted signal.
              Pushing up to 1500mW of transmission power, the ImmersionRC Hybrid
              Ultimate punches through the toughest RF environments, ensuring
              rock-solid connectivity when it matters most.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
