import * as React from 'react';

import { SEO } from 'main/components';

export class Download extends React.Component {
  render() {
    return (
      <>
        <SEO seoTitle="ORQA - Downloads" />
        <section className="page">
          <div className="wrapper wrapper--narrow">
            <h1 className="t-beta s-bottom--med">
              Orqa FPV&thinsp;&mdash;&thinsp;Product PR Photos repository
            </h1>
            <div className="page__content">
              <div className="page__table">
                <div className="page__table__item">
                  <div className="page__table__item__heading">
                    <p className="t-eta">Orqa FPV.P1 PR Photos</p>
                  </div>
                  <div className="page__table__item__action">
                    <a
                      href={require('main/assets/download/Orqa FPV.P1_PR_Photos.zip')}
                      className="btn btn--sml btn--nopadding"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseDown={event => event.preventDefault()}
                    >
                      Download
                    </a>
                  </div>
                </div>

                <div className="page__table__item">
                  <div className="page__table__item__heading">
                    <p className="t-eta">Orqa FPV.One Web Renders</p>
                  </div>
                  <div className="page__table__item__action">
                    <a
                      href={require('main/assets/download/OrqaFPV_OneRenders_web.zip')}
                      className="btn btn--sml btn--nopadding"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseDown={event => event.preventDefault()}
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
