import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

export const DefenseTablet = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [showVector, setShowVector] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : 0,
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getAdjustedPosition = (baseValue: string, index: number) => {
    if (windowWidth >= 4072) return baseValue;

    const baseNum = parseFloat(baseValue.replace('%', ''));
    const widthDifference = 4072 - windowWidth;
    const steps = Math.floor(widthDifference / 30);

    // Only adjust first and third indicators (index 0 and 2)
    if (index === 0 || index === 2) {
      const adjustment = steps * 0.3;
      const newValue = baseNum - adjustment;
      return `${newValue}%`;
    }

    return baseValue;
  };

  const getResponsiveIndicators = (windowWidth: number) => {
    return windowWidth > 820
      ? [
          // Large screen configuration (commented version)
          {
            position: { right: '18%', top: '32%' },
            vector: {
              svg: {
                width: '603',
                height: '70',
                viewBox: '0 0 603 70',
                path: 'M603.5 71L483 1.5H0',
              },
              position: { top: '17.5%', right: '26%' },
            },
            info: {
              top: getAdjustedPosition('57%', 0),
              left: getAdjustedPosition('60%', 0),
            },
            text: {
              title: 'MRM2 - 10F',
              description:
                'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV...',
            },
          },
          {
            position: { left: '25%', top: '35%' },
            vector: {
              svg: {
                width: '560',
                height: '127',
                viewBox: '0 0 560 127',
                path: 'M1 128.5L79 1H562',
              },
              position: { top: '13%', left: '20.5%' },
            },
            info: {
              top: '23%',
              left: '31%',
            },
            text: {
              title: 'FPV.Pro',
              description:
                'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity and ease of use...',
            },
          },
          {
            position: { right: '42%', bottom: '22%' },
            vector: {
              svg: {
                width: '483',
                height: '113',
                viewBox: '0 0 483 113',
                path: 'M241 114.5L1 1.5H484',
              },
              position: { bottom: '34.2%', right: '31.4%' },
            },
            info: {
              top: '30%',
              left: getAdjustedPosition('62.5%', 2),
            },
            text: {
              title: 'GCS-1',
              description:
                'Orqa GCS-1 Ground Control System is a complete solution...',
            },
          },
          {
            position: { left: '27%', bottom: '12%' },
            vector: {
              svg: {
                width: '606',
                height: '415',
                viewBox: '0 0 606 415',
                path: 'M1 416L124 1H607',
              },
              position: { bottom: '25%', left: '22.9%' },
            },
            info: {
              top: '32.5%',
              left: '38%',
            },
            text: {
              title: 'Tac.Ctrl',
              description:
                'Tac.Ctrl is a tactical remote controller for First Person View...',
            },
          },
        ]
      : [
          // Small screen configuration (non-commented version)
          {
            position: { right: '18%', top: '32%' },
            vector: {
              svg: {
                width: '609',
                height: '212',
                viewBox: '0 0 609 212',
                path: 'M609 214L483 1.5H0',
              },
              position: { top: '10.5%', right: '31%' },
            },
            info: {
              top: getAdjustedPosition('50%', 0),
              left: getAdjustedPosition('37%', 0),
            },
            text: {
              title: 'MRM2 - 10F',
              description:
                'MRM2-10F is a light-weight multi-role First Person View (FPV) multicopter UAV...',
            },
          },
          {
            position: { left: '25%', top: '55%' },
            vector: {
              svg: {
                width: '500',
                height: '250',
                viewBox: '0 0 500 250',
                path: 'M1 251.5L91 1H574',
              },
              position: { top: '30%', left: '18.5%' },
            },
            info: {
              top: '37%',
              left: '35%',
            },
            text: {
              title: 'FPV.Pro',
              description:
                'Orqa FPV.Pro is a rugged FPV goggle, designed for simplicity...',
            },
          },
          {
            position: { right: '40%', bottom: '35%' },
            vector: {
              svg: {
                width: '483',
                height: '131',
                viewBox: '0 0 483 131',
                path: 'M424 132L483 1H0',
              },
              position: { bottom: '43%', right: '47.4%' },
            },
            info: {
              top: '32.5%',
              left: getAdjustedPosition('37%', 2),
            },
            text: {
              title: 'GCS-1',
              description:
                'Orqa GCS-1 Ground Control System is a complete solution...',
            },
          },
          {
            position: { left: '27%', bottom: '12%' },
            vector: {
              svg: {
                width: '563',
                height: '590',
                viewBox: '0 0 563 590',
                path: 'M1 591L81 1H564',
              },
              position: { bottom: '20%', left: '20.5%' },
            },
            info: {
              top: '36.5%',
              left: '35%',
            },
            text: {
              title: 'Tac.Ctrl',
              description:
                'Tac.Ctrl is a tactical remote controller for First Person View...',
            },
          },
        ];
  };

  const indicators = getResponsiveIndicators(windowWidth);

  const openIndicator = (index: number) => {
    setActiveIndex(index);
    setShowVector(false);
    setShowText(false);

    setTimeout(() => {
      setShowVector(true); // Only show vector after circle completes

      // Start text animation after vector completes
      setTimeout(() => {
        setShowText(true);
      }, 300);
    }, 300);
  };

  const closeIndicator = () => {
    setIsClosing(true);
    setShowText(false);

    setTimeout(() => {
      setShowVector(false);
      setIsClosing(false);
      setActiveIndex(null);
    }, 300);
  };

  const handleIndicatorClick = (index: number) => {
    if (activeIndex === index) {
      closeIndicator();
    } else if (activeIndex !== null) {
      closeIndicator();
      setTimeout(() => openIndicator(index), 300);
    } else {
      openIndicator(index);
    }
  };

  return (
    <div className="tv-container">
      <h1 className="main-title">Rugged tactical defense solution</h1>
      <div className="defense-cta-section">
        <a
          href="https://orqafpv.com/asda/contact"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn--secondary btn--sml defense-cta-button"
          onMouseDown={event => event.preventDefault()}
        >
          get in touch
        </a>
      </div>

      {indicators.map((indicator, index) => (
        <div
          key={index}
          className="circle-indicator"
          style={{
            position: 'absolute',
            width: '58px', // Changed from 160px
            height: '58px', // Changed from 160px
            ...indicator.position,
            cursor: 'pointer',
          }}
          onClick={() => handleIndicatorClick(index)}
        >
          {/* Always render both SVGs, but control visibility with opacity */}
          <motion.div
            initial={{ opacity: 1 }}
            animate={{
              opacity: activeIndex === index ? 0 : 1,
              scale: activeIndex === index ? 0.8 : 1,
            }}
            transition={{ duration: 0.5 }}
            style={{ position: 'absolute' }}
          >
            <motion.svg
              viewBox="0 0 58 58" // Changed from 160 160
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="29" // Changed from 80 (58 * 0.5)
                cy="29" // Changed from 80
                r="29" // Changed from 80 (58 * 0.5)
                fill="url(#paint0_radial_123_925)"
              />
              <circle cx="29" cy="29" r="9.886" fill="white" />{' '}
              {/* Changed from 27.2727 (scaled proportionally) */}
              <defs>
                <motion.radialGradient
                  id="paint0_radial_123_925"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(29 29) rotate(90) scale(29)" // Changed scale from 80 to 29
                >
                  <motion.stop
                    stopColor="white"
                    animate={{ stopOpacity: [0.1, 0.3, 0.1] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: 'easeInOut',
                    }}
                  />
                  <motion.stop
                    offset="1"
                    stopColor="white"
                    animate={{ stopOpacity: [0.1, 0.3, 0.1] }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: 'easeInOut',
                      delay: 0.2,
                    }}
                  />
                </motion.radialGradient>
              </defs>
            </motion.svg>
          </motion.div>

          {/* Red circle (always present but scales in when active) */}
          <motion.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{
              opacity: activeIndex === index ? 1 : 0,
              scale: activeIndex === index ? 1 : 0,
            }}
            transition={{ duration: 0.5, ease: 'backOut' }}
            style={{ position: 'absolute' }}
          >
            <svg
              viewBox="0 0 58 58" // Changed from 160 160
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="29" // Changed from 80
                cy="29" // Changed from 80
                r="29" // Changed from 80
                fill="url(#paint0_radial_127_1107)"
              />
              <circle cx="29" cy="29" r="9.886" fill="#EE2737" />{' '}
              {/* Changed from 27.2727 */}
              <defs>
                <radialGradient
                  id="paint0_radial_127_1107"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(29 29) rotate(90) scale(29)" // Changed scale from 80 to 29
                >
                  <stop stopColor="#F1274C" stopOpacity="0.3" />
                  <stop offset="1" stopColor="#F1274C" stopOpacity="0" />
                </radialGradient>
              </defs>
            </svg>
          </motion.div>
        </div>
      ))}

      <AnimatePresence>
        {showVector && activeIndex !== null && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              ...indicators[activeIndex].vector.position,
              transform: 'translate(80px, 80px)',
            }}
          >
            <svg
              width={indicators[activeIndex].vector.svg.width}
              height={indicators[activeIndex].vector.svg.height}
              viewBox={indicators[activeIndex].vector.svg.viewBox}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <motion.path
                d={indicators[activeIndex].vector.svg.path}
                stroke="#EE2737"
                strokeWidth="5.06546"
                initial={{ pathLength: 0 }}
                animate={isClosing ? { pathLength: 0 } : { pathLength: 1 }}
                transition={{ duration: 0.5, ease: 'easeInOut' }}
              />
            </svg>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showText && activeIndex !== null && !isClosing && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="info-container"
            style={{
              position: 'absolute',
              top: indicators[activeIndex].info.top,
              left: indicators[activeIndex].info.left,
            }}
          >
            <motion.div
              initial={{ y: -20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.2 }}
              className="info-title"
            >
              {indicators[activeIndex].text.title}
            </motion.div>
            <motion.div
              initial={{ y: 20 }}
              animate={{ y: 0 }}
              transition={{ duration: 0.2, delay: 0.2 }}
              className="info-description"
            >
              {indicators[activeIndex].text.description}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
