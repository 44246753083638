import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface ScrollDownIndicatorProps {
  screenSize: number;
}

export const ScrollDownIndicator = ({
  screenSize,
}: ScrollDownIndicatorProps) => {
  const [shouldShow, setShouldShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const currentRef = ref.current;
    if (!currentRef) return;

    let timer: NodeJS.Timeout;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          timer = setTimeout(() => {
            setShouldShow(true);
          }, 2000);
        } else {
          setShouldShow(false);
          clearTimeout(timer);
        }
      },
      { threshold: 1.0 },
    );

    observer.observe(currentRef);

    return () => {
      clearTimeout(timer);
      observer.disconnect();
    };
  }, []);

  // Mobile animation variants (screenSize < 992)
  const wheelVariants = {
    animate: {
      y: [0, 5],
      opacity: [1, 0],
      transition: {
        y: {
          repeat: Infinity,
          duration: 2,
          ease: 'easeInOut',
        },
        opacity: {
          repeat: Infinity,
          duration: 2,
          ease: 'easeInOut',
        },
      },
    },
  };

  // Desktop animation variants (screenSize >= 992)
  const chevronVariants = {
    animate: {
      y: [0, 10, 0],
      opacity: [0.6, 1, 0.6],
      transition: {
        duration: 1.5,
        repeat: Infinity,
        ease: 'easeInOut',
      },
    },
  };

  const isMobile = screenSize < 992;

  return (
    <div
      ref={ref}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '120px 0',
        minHeight: '1px',
        width: '100%',
      }}
    >
      <motion.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: shouldShow ? 1 : 0,
          transition: { duration: 0.5 },
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {isMobile ? (
          /* Mobile Animation */
          <>
            <motion.div
              animate={chevronVariants.animate}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M7 13L12 18L17 13M7 7L12 12L17 7"
                  stroke="#333"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </motion.div>
            <motion.p
              animate={{
                opacity: [0.6, 1, 0.6],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
              }}
              style={{
                fontSize: '14px',
                color: '#666',
                marginTop: '8px',
                textAlign: 'center',
                width: '100%',
              }}
            >
              Scroll down
            </motion.p>
          </>
        ) : (
          /* Desktop Animation */
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              margin: '20px 0',
              cursor: 'pointer',
            }}
          >
            <motion.div
              style={{
                width: '30px',
                height: '50px',
                borderRadius: '15px',
                border: '2px solid #333',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '10px',
              }}
            >
              <motion.div
                style={{
                  width: '4px',
                  height: '10px',
                  borderRadius: '2px',
                  backgroundColor: '#333',
                }}
                variants={wheelVariants}
                animate="animate"
              />
            </motion.div>
            <motion.p
              style={{ marginTop: '10px', color: '#666' }}
              animate={{
                opacity: [0.6, 1, 0.6],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
              }}
            >
              Scroll down
            </motion.p>
          </div>
        )}
      </motion.div>
    </div>
  );
};
