import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import * as React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import Slider from 'react-slick';

// import {
//   PageNavFPVConnect,
//   PageNavFPVCtrl,
//   PageNavFPVOne,
// } from 'main/components';

import { ProgressBar } from './ProggressBar';

// import imgFpvAce from '../assets/images/navigation/fpv-ace.png';
// import imgLaprf from '../assets/images/navigation/laprf.png';
// import imgSkyDive from '../assets/images/navigation/sky-dive.png';
// import imgRapidFire from '../assets/images/navigation/rapidfire.png';
// import imgRfPowerMeter from '../assets/images/navigation/rf-power-meter.png';
import facebookIcon from '../assets/images/icon-social-facebook.svg';
import instagramIcon from '../assets/images/icon-social-instagram.svg';
import youtubeIcon from '../assets/images/icon-social-youtube.svg';
import xIcon from '../assets/images/icon-social-x.svg';
import { ReactComponent as UpArrow } from '../assets/icons/up-arrow-svgrepo-com.svg';

interface NavigationState {
  isMenuToggled: boolean;
  isProductsOpen: boolean;
}

type Props = RouteComponentProps<any>;

class Navigation extends React.Component<Props, NavigationState> {
  state = {
    isMenuToggled: false,
    isProductsOpen: false,
  };

  private targetElement: Element | null = null;
  private outsideClickRef = React.createRef<HTMLDivElement>();
  private excludeRef = React.createRef<HTMLLIElement>();
  private excludeRef2 = React.createRef<HTMLLIElement>();

  componentDidMount() {
    this.targetElement = document.querySelector('.nav__links');
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (
      this.outsideClickRef.current &&
      !this.outsideClickRef.current.contains(event.target) &&
      !(
        this.excludeRef.current &&
        this.excludeRef.current.contains(event.target)
      ) &&
      !(
        this.excludeRef2.current &&
        this.excludeRef2.current.contains(event.target)
      )
    ) {
      // Exclude this element
      this.setState({ isProductsOpen: false });
      this.removeBodyLock();
    }
  };

  componentDidUpdate(prevProps: Props) {
    const { pathname } = this.props.location;
    const { isProductsOpen } = this.state;

    if (pathname !== prevProps.location.pathname) {
      this.removeBodyLock();
      this.setState({
        isMenuToggled: false,
      });
      document.body.classList.remove('is-locked');
      document.documentElement.classList.remove('is-locked');
    }

    const glassEffectElement = document.getElementById('glass__effect');

    if (glassEffectElement) {
      if (isProductsOpen) {
        glassEffectElement.style.display = 'block'; // 👈 Ensure it is visible
      } else {
        glassEffectElement.style.display = 'none'; // 👈 Hide it
      }
    }
  }

  preventDefault = (e: any) => {
    e.preventDefault();
  };

  handleMenuToggle = () => {
    const { isMenuToggled } = this.state;
    this.setState({ isMenuToggled: !isMenuToggled });
    if (isMenuToggled) {
      this.removeBodyLock();
    } else {
      document.body.classList.add('is-locked');
      document.documentElement.classList.add('is-locked');
      disableBodyScroll(this.targetElement);
    }
  };

  removeBodyLock = () => {
    document.body.classList.remove('is-locked');
    document.documentElement.classList.remove('is-locked');
    enableBodyScroll(this.targetElement);

    this.setState({ isProductsOpen: false });
  };

  handleBlur = (event: any) => {
    event.currentTarget ? event.currentTarget.blur() : event.srcElement.blur();
  };

  handleProductsToggle = () => {
    const { isProductsOpen } = this.state;

    if (isProductsOpen) {
      this.removeBodyLock();
    } else {
      document.body.classList.add('is-locked');
      document.documentElement.classList.add('is-locked');
      disableBodyScroll(this.targetElement);
    }

    this.setState({ isProductsOpen: !isProductsOpen });
  };
  handleNavigationClick = () => {
    this.setState({ isProductsOpen: false });
    this.removeBodyLock();
  };

  render() {
    const { isMenuToggled, isProductsOpen } = this.state;

    const settings: any = {
      autoplay: false,
      dots: true,
      speed: 500,
      centerPadding: '60px',
      slidesToShow: 9,
      infinite: false,
      arrows: true,
      swipe: false,
      swipeToSlide: false,
      height: '180px',
      gap: '43px',
      slidesToScroll: 1,
      responsive: [
        { breakpoint: 2200, settings: { slidesToShow: 8 } },
        { breakpoint: 1920, settings: { slidesToShow: 7 } },
        { breakpoint: 1675, settings: { slidesToShow: 6 } },
        { breakpoint: 1370, settings: { slidesToShow: 5 } },
        { breakpoint: 1150, settings: { slidesToShow: 4 } },
      ],
    };

    const activeLocation = this.props.location.pathname;

    return (
      <>
        <nav className={`nav ${isMenuToggled ? 'is-open' : ''}`}>
          <div className={`nav__links ${isMenuToggled ? 'is-open' : ''}`}>
            <div
              style={{
                opacity: isProductsOpen ? '1' : '0',
                pointerEvents: isProductsOpen ? 'inherit' : 'none',
                position: 'absolute',
                transition: 'all .2s ease-out',
                top: '49px',
                zIndex: 7,
                backgroundColor: 'black',
              }}
              ref={this.outsideClickRef}
              className="products__nav__parent"
              id="nav__products__wrapper"
            >
              <div
                style={{
                  width: '90%',
                  margin: '0 auto',
                  minHeight: '250px',
                  textAlign: 'center',
                }}
                className="products__slider"
              >
                <Slider {...settings}>
                  <div className="image__block">
                    <NavLink
                      to={'/fpvonepilot'}
                      onClick={this.handleNavigationClick}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                    >
                      <div className="products__image--wrapper">
                        <img
                          src={require('main/assets/images/fpvonepilot/ORQA_MKII_SML.webp')}
                          alt=""
                          className="products__dropdown__image"
                        />
                      </div>
                      <span>FPV.One Pilot</span>
                    </NavLink>
                  </div>
                  <div className="image__block">
                    <NavLink
                      to="/fpvonerace"
                      onClick={this.handleNavigationClick}
                      onMouseLeave={this.handleBlur}
                      exact
                      className="products__dropdown__nav"
                    >
                      <div className="products__image--wrapper">
                        <img
                          src={require('main/assets/images/fpvonerace/fpvone-race-white.webp')}
                          alt=""
                          className="products__dropdown__image"
                        />
                      </div>
                      <span>FPV.One Race</span>
                    </NavLink>
                  </div>
                  <div className="image__block">
                    <NavLink
                      to="/fpvctrl"
                      onClick={this.handleNavigationClick}
                      onMouseLeave={this.handleBlur}
                      exact
                      className="products__dropdown__nav"
                    >
                      <div className="products__image--wrapper">
                        <img
                          src={require('main/assets/images/fpvctrl/fpvctrl-full.webp')}
                          alt=""
                          className="products__dropdown__image"
                        />
                      </div>
                      <span>FPV.Ctrl</span>
                    </NavLink>
                  </div>
                  {/* <div className="image__block">
                    <NavLink
                      to={'/fpvonepilot'}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                    >
                      <img
                        src={imgFpvAce}
                        alt=""
                        className="products__dropdown__image"
                      />
                      <span>FPV.Ace</span>
                    </NavLink>
                  </div> */}
                  <div className="image__block">
                    <NavLink
                      to={'/dreamx'}
                      onClick={this.handleNavigationClick}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                    >
                      <div className="products__image--wrapper">
                        <img
                          src={require('main/assets/images/dreamX/DreamX_dron.webp')}
                          alt=""
                          className="products__dropdown__image"
                        />
                      </div>
                      <span>Dream X</span>
                    </NavLink>
                  </div>
                  {/* <div className="image__block">
                    <NavLink
                      to={'/fpvace'}
                      onClick={this.handleNavigationClick}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                    >
                      <div className="products__image--wrapper">
                        <img
                          src={require('main/assets/images/fpvAce/Bundle.webp')}
                          alt="FPV Ace product"
                          className="products__dropdown__image"
                        />
                      </div>
                      <span>FPV Ace</span>
                    </NavLink>
                  </div> */}

                  {/* <div className="image__block">
                    <NavLink
                      to={'/fpvonepilot'}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                    >
                      <img
                        src={imgSkyDive}
                        alt=""
                        className="products__dropdown__image"
                      />
                      <span>SkyDive</span>
                    </NavLink>
                  </div> */}
                  {/* <div className="image__block">
                    <NavLink
                      to={'/fpvonepilot'}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                    >
                      <img
                        src={imgRfPowerMeter}
                        alt=""
                        className="products__dropdown__image"
                      />
                      <span>RF PowerMeter v2</span>
                    </NavLink>
                  </div> */}
                  {/* <div className="image__block">
                    <NavLink
                      to={'/fpvonepilot'}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                      activeClassName="active"
                    >
                      <img
                        src={imgRapidFire}
                        alt=""
                        className="products__dropdown__image"
                      />
                      <span>rapidFire</span>
                    </NavLink>
                  </div> */}
                  {/* <div className="image__block">
                    <NavLink
                      to={'/fpvonepilot'}
                      onMouseLeave={this.handleBlur}
                      className="products__dropdown__nav"
                      activeClassName="active"
                    >
                      <img
                        src={imgLaprf}
                        alt=""
                        className="products__dropdown__image"
                      />
                      <span>ImmersionRC LapRF 8</span>
                    </NavLink>
                  </div> */}
                </Slider>
              </div>
            </div>

            <ul className="nav__links__top">
              <li
                ref={this.excludeRef2}
                onClick={this.handleProductsToggle}
                className="nav__links__item nav__links__item--sub-nav local--desktop"
              >
                <p
                  className={`nav__link nav__links__item__subtitle nav__links__item__subtitle--secondary ${
                    isProductsOpen ? 'active' : ''
                  }`}
                  style={{
                    paddingLeft: '0px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  Products
                  <UpArrow
                    className={`nav__desktop__arrow ${
                      isProductsOpen ? 'nav__mobile__arrow--active' : ''
                    }`}
                  />
                </p>
              </li>

              <li
                ref={this.excludeRef}
                className="nav__links__item nav__links__item--mobile"
              >
                <a
                  onClick={this.handleProductsToggle}
                  className={`nav__link products__nav--mobile ${
                    isProductsOpen ? 'products__nav--mobile--active' : ''
                  }`}
                  target="_blank"
                  role="button"
                  rel="noopener noreferrer"
                  style={{ paddingLeft: '0px' }}
                  onMouseDown={event => event.preventDefault()}
                >
                  <span
                    className={`products__mobile ${
                      isProductsOpen ? 'products__mobile--active' : ''
                    }`}
                  >
                    Products
                  </span>
                  <UpArrow
                    className={`nav__mobile__arrow ${
                      isProductsOpen ? 'nav__mobile__arrow--active' : ''
                    }`}
                  />
                </a>
                <div
                  className={`nav__mobile__list ${
                    isProductsOpen ? 'nav__mobile__list--active' : ''
                  }`}
                >
                  <NavLink
                    to={'/fpvonepilot'}
                    onClick={() => {
                      this.handleNavigationClick();
                      this.handleMenuToggle();
                    }}
                    activeClassName="active"
                    onMouseLeave={this.handleBlur}
                    className={`nav__mobile__item ${
                      activeLocation === '/fpvonepilot'
                        ? 'nav__mobile__item--active'
                        : ''
                    }`}
                  >
                    <div className="nav__mobile__image--wrapper">
                      <img
                        src={require('main/assets/images/fpvonepilot/ORQA_MKII_SML.webp')}
                        alt=""
                        className="nav__mobile__image"
                      />
                    </div>
                    <span style={{ fontSize: '16px' }}>FPV.One Pilot</span>
                  </NavLink>
                  <NavLink
                    to="/fpvonerace"
                    onClick={() => {
                      this.handleNavigationClick();
                      this.handleMenuToggle();
                    }}
                    onMouseLeave={this.handleBlur}
                    exact
                    activeClassName="active"
                    className={`nav__mobile__item ${
                      activeLocation === '/fpvonerace'
                        ? 'nav__mobile__item--active'
                        : ''
                    }`}
                  >
                    <div className="nav__mobile__image--wrapper">
                      <img
                        src={require('main/assets/images/fpvonerace/fpvone-race-white.webp')}
                        alt=""
                        className="nav__mobile__image"
                      />
                    </div>
                    <span style={{ fontSize: '16px' }}>FPV.One Race</span>
                  </NavLink>
                  <NavLink
                    to="/fpvctrl"
                    onClick={() => {
                      this.handleNavigationClick();
                      this.handleMenuToggle();
                    }}
                    onMouseLeave={this.handleBlur}
                    exact
                    activeClassName="active"
                    className={`nav__mobile__item ${
                      activeLocation === '/fpvctrl'
                        ? 'nav__mobile__item--active'
                        : ''
                    }`}
                  >
                    <div className="nav__mobile__image--wrapper">
                      <img
                        src={require('main/assets/images/fpvctrl/fpvctrl-full.webp')}
                        alt=""
                        className="nav__mobile__image"
                      />
                    </div>
                    <span style={{ fontSize: '16px' }}>FPV.Ctrl</span>
                  </NavLink>
                  <NavLink
                    to={'/dreamx'}
                    onClick={() => {
                      this.handleNavigationClick();
                      this.handleMenuToggle();
                    }}
                    onMouseLeave={this.handleBlur}
                    activeClassName="active"
                    className={`nav__mobile__item ${
                      activeLocation === '/dreamx'
                        ? 'nav__mobile__item--active'
                        : ''
                    }`}
                  >
                    <div className="nav__mobile__image--wrapper">
                      <img
                        src={require('main/assets/images/dreamX/DreamX_dron.webp')}
                        alt=""
                        className="nav__mobile__image"
                      />
                    </div>
                    <span
                      style={{ fontSize: '16px' }}
                      className="nav__mobile__title"
                    >
                      Dream X
                    </span>
                  </NavLink>
                  {/* <NavLink
                    to={'/fpvace'}
                    onClick={() => {
                      this.handleNavigationClick();
                      this.handleMenuToggle();
                    }}
                    onMouseLeave={this.handleBlur}
                    activeClassName="active"
                    className={`nav__mobile__item ${
                      activeLocation === '/fpvace'
                        ? 'nav__mobile__item--active'
                        : ''
                    }`}
                  >
                    <div className="nav__mobile__image--wrapper">
                      <img
                        src={require('main/assets/images/fpvAce/Bundle.webp')}
                        alt=""
                        className="nav__mobile__image"
                      />
                    </div>
                    <span
                      style={{ fontSize: '16px' }}
                      className="nav__mobile__title"
                    >
                      FPV Ace
                    </span>
                  </NavLink> */}
                </div>
              </li>

              <li className="nav__links__item">
                <a
                  href="https://enterprise.orqafpv.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav__link"
                  onMouseDown={event => event.preventDefault()}
                >
                  Enterprise
                </a>
              </li>
              <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/news"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  News
                </NavLink>
              </li>
              <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/about"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  About
                </NavLink>
              </li>
              <li className="nav__links__item">
                <a
                  href="https://orqafpv.freshdesk.com/support/solutions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav__link"
                  onMouseDown={event => event.preventDefault()}
                >
                  Support
                </a>
              </li>
              {/* <li className="nav__links__item">
                <NavLink
                  onMouseLeave={this.handleBlur}
                  to="/fpvconnect"
                  exact
                  className="nav__link"
                  activeClassName="active"
                  onClick={this.removeBodyLock}
                >
                  FPV.Connect
                </NavLink>
              </li>

              <li className="nav__links__item">
                <a
                  onMouseLeave={this.handleBlur}
                  href="https://bit.ly/fpvskydive20"
                  className="nav__link"
                  onClick={this.removeBodyLock}
                  rel="noopener noreferrer"
                  target="_blank"
                                      onMouseDown={event => event.preventDefault()}
                >
                  Skydive
                </a>
              </li> */}
            </ul>
            <div className="social__wrapper">
              <div className="social">
                <a
                  href="https://www.facebook.com/OrqaFPV"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  <img
                    src={facebookIcon}
                    alt="ORQA on Facebook"
                    className="header__icon"
                  />
                </a>
                <a
                  href="https://www.instagram.com/orqafpv/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  <img
                    src={instagramIcon}
                    alt="ORQA on Instagram"
                    className="header__icon"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCGPZJ5j16slPTObilo8viwg/featured"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  <img
                    src={youtubeIcon}
                    alt="ORQA on Youtube"
                    className="header__icon"
                  />
                </a>
                <a
                  href="https://twitter.com/OrqaFPV"
                  target="_blank"
                  rel="noopener noreferrer"
                  onMouseDown={event => event.preventDefault()}
                >
                  <img
                    src={xIcon}
                    alt="ORQA on Twitter"
                    className="header__icon"
                  />
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="nav__toggle">
              <button
                onClick={this.handleMenuToggle}
                className={`hamburger hamburger--elastic ${
                  isMenuToggled ? 'is-active' : ''
                }`}
              >
                <span className="hamburger-box">
                  <span className="hamburger-inner" />
                </span>
              </button>
            </div>
            <div className="nav__proggress--bar">
              <ProgressBar />
            </div>
          </div>
          {/* <PageNavFPVOne />
          <PageNavFPVConnect />
          <PageNavFPVCtrl /> */}
        </nav>
      </>
    );
  }
}

export default withRouter(Navigation);
