import React, { useState, useEffect, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

const CookiesBanner = () => {
  const [adsMarketingChecked, setAdsMarketingChecked] = useState(true);
  const [
    analyticsPerformanceChecked,
    setAnalyticsPerformanceChecked,
  ] = useState(true);
  const [supportLiveChatChecked, setSupportLiveChatChecked] = useState(true);
  const [showBanner, setShowBanner] = useState(false);

  const allChecked = useMemo(
    () =>
      adsMarketingChecked &&
      analyticsPerformanceChecked &&
      supportLiveChatChecked,
    [adsMarketingChecked, analyticsPerformanceChecked, supportLiveChatChecked],
  );

  const allUnchecked = useMemo(
    () =>
      !adsMarketingChecked &&
      !analyticsPerformanceChecked &&
      !supportLiveChatChecked,
    [adsMarketingChecked, analyticsPerformanceChecked, supportLiveChatChecked],
  );

  useEffect(() => {
    const userCookies = document.cookie
      .split('; ')
      .find(row => row.startsWith('user-cookies='));
    if (!userCookies) {
      setShowBanner(true);
    }
  }, []);

  const handleDeclineAll = () => {
    document.cookie = 'user-cookies=NONE; path=/; max-age=31536000'; // 1 year
    setShowBanner(false);
  };

  const handleSaveUserCookies = () => {
    let selectedCookies = [];
    if (adsMarketingChecked) selectedCookies.push('ads');
    if (analyticsPerformanceChecked) selectedCookies.push('analytics');
    if (supportLiveChatChecked) selectedCookies.push('support');
    if (allUnchecked) {
      selectedCookies = [];
      selectedCookies.push('NONE');
    }
    document.cookie = `user-cookies=${selectedCookies.join(
      ',',
    )}; path=/; max-age=31536000`; // 1 year
    setShowBanner(false);
    document.dispatchEvent(new CustomEvent('cookiesAccepted'));
  };

  if (!showBanner) return null;

  return (
    <div
      className="cookie-banner"
      style={{ display: showBanner ? 'block' : 'none' }}
    >
      <div className="cookie-content">
        <h3>Cookie Settings</h3>
        <p>
          We use cookies to provide you with the best possible experience. They
          also allow us to analyze user behavior in order to constantly improve
          the website for you.{' '}
          <NavLink to="/privacy-policy" className="cookie-content-darker-link">
            Privacy Policy Information
          </NavLink>
        </p>

        <div className="cookie-actions">
          <div className="cookie-options">
            <label>
              <input
                type="checkbox"
                checked={adsMarketingChecked}
                onChange={e => setAdsMarketingChecked(e.target.checked)}
              />
              Advertising & Marketing Cookies
            </label>
            <label>
              <input
                type="checkbox"
                checked={analyticsPerformanceChecked}
                onChange={e => setAnalyticsPerformanceChecked(e.target.checked)}
              />
              Analytics & Performance Cookies
            </label>
            <label>
              <input
                type="checkbox"
                checked={supportLiveChatChecked}
                onChange={e => setSupportLiveChatChecked(e.target.checked)}
              />
              Support & Live Chat Cookies
            </label>
          </div>

          <div className="cookie-buttons">
            <button onClick={handleSaveUserCookies} disabled={allUnchecked}>
              {allChecked ? 'Accept All' : 'Accept Selected'}
            </button>
            <button onClick={handleDeclineAll}>Decline All</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesBanner;
